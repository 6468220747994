import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IntervalType } from '../../enums/interval-type.enum';

@Injectable({
	providedIn: 'root',
})
export class DateTimeService {
	constructor() {}

	/**
	 * Transform from format "hhmm" 24-hour to date.
	 *
	 * E.g 1345 => new Date() with 13 hours and 45 minutes
	 *
	 * @param time: max 4 numbers with format "hhmm" (E.g 1 => 00:01, 12 => 00:12, 123 => 01:23, 1234 => 12:34)
	 */
	public getFormattedTime(time: number) {
		if (time) {
			const formattedTime = time.toLocaleString('en-us', { minimumIntegerDigits: 4, useGrouping: false });
			const date = new Date();
			date.setHours(+formattedTime.substring(0, 2), +formattedTime.substring(2, 4), 0, 0);
			return date;
		} else {
			return new Date();
		}
	}

	public getFormattedTimeForHTML(time: number) {
		if (time) {
			const formattedTime = time.toLocaleString('en-us', { minimumIntegerDigits: 4, useGrouping: false });
			return `${+formattedTime.substring(0, 2)}:${formattedTime.substring(2, 4)}`;
		} else {
			return '-';
		}
	}

	public formattedElapsedDate(date) {
		if (date) {
			const formatElapsedTime = moment.duration(date, 's');
			let elapsedTime = [];
			if (formatElapsedTime.days() > 0) {
				elapsedTime.push(formatElapsedTime.days() + 'd');
			}
			if (formatElapsedTime.hours() > 0) {
				elapsedTime.push(' ' + formatElapsedTime.hours() + 'h');
			}
			if (formatElapsedTime.minutes() > 0) {
				elapsedTime.push(' ' + formatElapsedTime.minutes() + 'm');
			}
			let string = '';
			elapsedTime.forEach(e => {
				string += e;
			});
			return string;
		}
	}

	public getIntervalDates(intervalType: IntervalType): Date[] {
		let startDate: Date;
		let endDate: Date;
		switch (intervalType) {
			case IntervalType.Today:
				startDate = this.getDateWithoutTime(new Date());
				endDate = this.getDateWithoutTime(new Date());
				endDate.setDate(endDate.getDate() + 1);
				endDate.setHours(-1);
				break;
			case IntervalType.Yesterday:
				startDate = this.getDateWithoutTime(new Date());
				startDate.setDate(startDate.getDate() - 1);
				endDate = this.getDateWithoutTime(new Date());
				endDate.setHours(-1);
				break;
			case IntervalType.Last7Days:
				startDate = this.getDateWithoutTime(new Date());
				startDate.setDate(startDate.getDate() - 6);
				endDate = this.getDateWithoutTime(new Date());
				endDate.setDate(endDate.getDate() + 1);
				endDate.setHours(-1);
				break;
			case IntervalType.LastMonth:
				startDate = this.getDateWithoutTime(new Date());
				startDate.setDate(startDate.getDate() - 30);
				endDate = this.getDateWithoutTime(new Date());
				endDate.setDate(endDate.getDate() + 1);
				endDate.setHours(-1);
				break;
			default:
				break;
		}

		return [startDate, endDate];
	}

	private getDateWithoutTime(date: Date): Date {
		var date = new Date(date.getTime());
		date.setHours(0, 0, 0, 0);
		return date;
	}
}
