import { ProductModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const productInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: '/products/:id',
		permissionId: ProductModuleTabs.Info,
	},
	{
		name: 'Evaluations',
		path: '/products/:id/evaluations',
		permissionId: ProductModuleTabs.Evaluations,
	},
	{
		name: 'Formulas',
		path: '/products/:id/formulas',
		permissionId: ProductModuleTabs.Formulas,
	},
	{
		name: 'Facilities',
		path: '/products/:id/facilities',
		permissionId: ProductModuleTabs.Facilities,
	},
	{
		name: 'Processes',
		path: '/products/:id/processes',
		permissionId: ProductModuleTabs.Processes,
	},
	{
		name: 'Same As Products',
		path: '/products/:id/same-as',
		permissionId: ProductModuleTabs.SameAs,
	},
	{
		name: 'Dependent Products',
		path: '/products/:id/dependent-products',
		permissionId: ProductModuleTabs.DependentProducts,
	},
	{
		name: 'Notes',
		path: '/products/:id/notes',
		permissionId: ProductModuleTabs.Notes,
	},
	{
		name: 'History Notes',
		path: '/products/:id/history',
		permissionId: ProductModuleTabs.History,
	},
	{
		name: 'Categories',
		path: '/products/:id/categories',
		permissionId: ProductModuleTabs.Categories,
	},
	{
		name: 'Kosher Certificates',
		path: '/products/:id/kosher-certificates',
		permissionId: ProductModuleTabs.KosherCertificates,
	},

	{
		name: 'Production History',
		path: '/products/:id/production-history',
		permissionId: ProductModuleTabs.ProductionHistory,
	},
	{
		name: 'Product Labels',
		path: '/products/:id/product-labels',
		permissionId: ProductModuleTabs.ProductLabels,
	},
	{
		name: 'KC Group',
		path: '/products/:id/kc-group',
		permissionId: ProductModuleTabs.KCGroup,
	},
	{
		name: 'Tasks',
		path: '/products/:id/tasks',
		permissionId: ProductModuleTabs.Tasks,
	},
	{
		name: 'Audit Trail',
		path: `/products/:id/audit-trail`,
	},
];
