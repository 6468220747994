import { VisitModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const visitInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/visits/:id`,
		permissionId: VisitModuleTabs.Info,
	},
	{
		name: 'Report questions',
		path: `/visits/:id/report-questions`,
		permissionId: VisitModuleTabs.ReportQuestions,
	},
	{
		name: 'Products',
		path: `/visits/:id/products`,
		permissionId: VisitModuleTabs.Products,
	},
	{
		name: 'Payment Notes',
		path: `/visits/:id/payment-notes`,
		permissionId: VisitModuleTabs.PaymentNotes,
	},
	{
		name: 'Notes',
		path: `/visits/:id/notes`,
		permissionId: VisitModuleTabs.Notes,
	},
	{
		name: 'Facility Contract Notes',
		path: `/visits/:id/facility-contract-notes`,
		permissionId: VisitModuleTabs.Notes,
	},
	{
		name: 'Expenses',
		path: `/visits/:id/expenses`,
		permissionId: VisitModuleTabs.Expenses,
	},
	{
		name: 'Tasks',
		path: `/visits/:id/tasks`,
		permissionId: VisitModuleTabs.Tasks,
	},
	{
		name: 'Audit Trail',
		path: `/visits/:id/audit-trail`,
	},
];
