import { ActiveType } from 'src/app/core/enums/active-type.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgreementType } from 'src/app/core/enums/agreement-type.enum';
import { ApplicationType } from 'src/app/core/enums/application-type.enum';
import { BusinessEntityType } from 'src/app/core/enums/business-entity-type.enum';
import {
	ApplicationBusinessEntityMerge,
	ApplicationBusinessEntityMergeValue,
} from 'src/app/core/models/application-business-entity-merge.model';
import {
	ApplicationBusinessEntity,
	ApplicationCompany,
	ApplicationContact,
	ApplicationContacts,
	ApplicationDetail,
	ApplicationFacility,
	ApplicationInvoicingGroup,
	ApplicationSaveContactsContainer,
	ApplicationSummaryLight,
} from 'src/app/core/models/application.model';
import { BaseModel } from 'src/app/core/models/base.model';
import { ContractInfoDto } from 'src/app/core/models/contract-info.model';
import { InvoicingGroup } from 'src/app/core/models/invoicing-group.model';
import { MappingEntity } from 'src/app/core/models/mapping-entity.model';
import { CompareItemModel } from 'src/app/shared/shared-grids/compare-list/model/compare-item.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';

@Injectable({
	providedIn: 'root',
})
export class ApplicationsService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	publish(id): Observable<any> {
		return this.http.get<any>(`${this.appConfigurationService.api.service}/application/publish?id=${id}`);
	}

	applicationSummary(id): Observable<ApplicationSummaryLight> {
		return this.http.get<ApplicationSummaryLight>(
			`${this.appConfigurationService.api.service}/summary/application`,
			{
				params: { id },
			}
		);
	}

	applicationInfo(id: number, activeType = ActiveType.All): Observable<ApplicationDetail> {
		return this.http.get<ApplicationDetail>(
			`${this.appConfigurationService.api.service}/application/manage/info?id=${id}&activeType=${activeType}`
		);
	}

	applicationInfoSave(applicatioDetail): Observable<ApplicationDetail> {
		return this.http.post<ApplicationDetail>(
			`${this.appConfigurationService.api.service}/application/manage/info/save`,
			applicatioDetail
		);
	}

	getApplicationFacilities(ids: ApplicationBusinessEntity[]): Observable<ApplicationFacility[]> {
		return this.http.post<ApplicationFacility[]>(
			`${this.appConfigurationService.api.service}/application/manage/facilities`,
			ids
		);
	}

	applicationContacts(
		businessEntities: ApplicationBusinessEntity[],
		contacts: ApplicationContact[],
		applicationType: ApplicationType = ApplicationType.Company
	): Observable<any> {
		return this.http.post<any>(`${this.appConfigurationService.api.service}/application/manage/contacts`, {
			businessEntities,
			contacts,
			type: applicationType,
		});
	}

	saveApplicationContacts(request: ApplicationSaveContactsContainer): Observable<ApplicationContacts> {
		return this.http.post<ApplicationContacts>(
			`${this.appConfigurationService.api.service}/application/manage/contacts/save`,
			request
		);
	}

	saveApplicationFacilities(facilities, validateAddress: boolean = true): Observable<ApplicationFacility[]> {
		return this.http.post<ApplicationFacility[]>(
			`${this.appConfigurationService.api.service}/application/manage/facilities/save?validateAddress=${validateAddress}`,
			facilities
		);
	}

	linkFacilityToApplication(applicationId: number, companyId: number, facilityId: number) {
		return this.http.post<any>(`${this.appConfigurationService.api.service}/application/link`, {
			applicationId,
			companyId,
			facilityId,
		});
	}

	saveFacility(container: any) {
		return this.http.post<any>(
			`${this.appConfigurationService.api.service}/Application/Company/Facility/Save`,
			container
		);
	}

	applicationCompanyInfo(id): Observable<ApplicationCompany> {
		return this.http.get<ApplicationCompany>(
			`${this.appConfigurationService.api.service}/application/manage/company`,
			{
				params: { id },
			}
		);
	}

	saveApplicationCompanyInfo(company): Observable<ApplicationCompany> {
		return this.http.post<ApplicationCompany>(
			`${this.appConfigurationService.api.service}/application/manage/company/save`,
			company
		);
	}

	getMergedBusinessEntity(applicationBusinessEntityId: number): Observable<ApplicationBusinessEntityMerge> {
		return this.http.get<ApplicationBusinessEntityMerge>(
			`${this.appConfigurationService.api.service}/application/businessEntity/merge/load`,
			{ params: { applicationBusinessEntityId: applicationBusinessEntityId.toString() } }
		);
	}

	insertMergeDetails(
		mergeValue: ApplicationBusinessEntityMergeValue,
		applicationBusinessEntityId: number,
		mergedBusinessEntityId: number
	): Observable<ApplicationBusinessEntityMerge> {
		return this.http.post<ApplicationBusinessEntityMerge>(
			`${this.appConfigurationService.api.service}/application/businessEntity/merge/insert?applicationBusinessEntityId=${applicationBusinessEntityId}&mergedBusinessEntityId=${mergedBusinessEntityId}`,
			mergeValue
		);
	}

	updateMergeDetails(mergeValue: ApplicationBusinessEntityMerge): Observable<ApplicationBusinessEntityMerge> {
		return this.http.post<ApplicationBusinessEntityMerge>(
			`${this.appConfigurationService.api.service}/application/businessEntity/merge/update`,
			mergeValue
		);
	}

	deleteMergedApplicationBusinessEntity(id: number): Observable<number> {
		let params = new HttpParams();
		params = params.append('applicationBusinessEntityId', id.toString());
		return this.http.post<number>(
			`${this.appConfigurationService.api.service}/application/businessEntity/merge/undo`,
			null,
			{
				params,
			}
		);
	}

	mergeApplication(id: number): Observable<any> {
		return this.http.get<any>(`${this.appConfigurationService.api.service}/application/merge`, {
			params: { id: id.toString() },
		});
	}

	getInvoicesGroups(
		companyId: number,
		includeRelatedEntities = false,
		activeType = ActiveType.All
	): Observable<ApplicationInvoicingGroup> {
		return this.http.get<ApplicationInvoicingGroup>(
			`${this.appConfigurationService.api.service}/application/manage/invoicegroup/list?includeRelatedEntities=${includeRelatedEntities}&companyId=${companyId}&activeType=${activeType}`
		);
	}

	getInvoicesGroupsDropdown(companyId: number, includeRelatedEntities = false): Observable<any> {
		return this.http.get<any>(
			`${this.appConfigurationService.api.service}/application/manage/invoicegroup/select?includeRelatedEntities=${includeRelatedEntities}`,
			{
				params: { companyId: companyId.toString() },
			}
		);
	}

	getPrivateLabelerInvoicingGroupSelected(companyId: number, privateLabelerId: number): Observable<number> {
		return this.http.get<number>(
			`${this.appConfigurationService.api.service}/Company/InvoicingGroupEntity/PrivateLabeler/Selected/Load?companyId=${companyId}&privateLabelerId=${privateLabelerId}`
		);
	}

	savePrivateLabelerInvoicingGroup(
		companyId: number,
		privateLabelerId: number,
		invoicingGroupId: number,
		isPaidByPrivateLabeler: boolean
	): Observable<number> {
		return this.http.post<number>(
			`${this.appConfigurationService.api.service}/Company/InvoicingGroupEntity/PrivateLabeler/Selected/Save?companyId=${companyId}&privateLabelerId=${privateLabelerId}&invoicingGroupId=${invoicingGroupId}&isPaidByPrivateLabeler=${isPaidByPrivateLabeler}`,
			{}
		);
	}

	saveInvoiceGroup(invoiceGroup: InvoicingGroup): Observable<InvoicingGroup> {
		return this.http.post<InvoicingGroup>(
			`${this.appConfigurationService.api.service}/application/manage/invoicegroup/save`,
			invoiceGroup
		);
	}

	saveFacilityContract(contractInfo: ContractInfoDto): Observable<ContractInfoDto> {
		return this.http.post<ContractInfoDto>(
			`${this.appConfigurationService.api.service}/contract/save`,
			contractInfo
		);
	}

	getContractVisitTypes(companyId: number, facilityId: number): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.service}/contract/${companyId}/${facilityId}/visitTypes`
		);
	}

	getContractTermAudit(contractTermId: number, year: number): Observable<{ [id: string]: CompareItemModel[] }> {
		return this.http.get<{ [id: string]: CompareItemModel[] }>(
			`${this.appConfigurationService.api.service}/contract/term/audit?id=${contractTermId}&year=${year}`
		);
	}

	saveApplicationAgreementTypeId(
		applicationBusinessEntityId: number,
		agreementTypeId: AgreementType
	): Observable<ApplicationBusinessEntity> {
		return this.http.post<ApplicationBusinessEntity>(
			`${this.appConfigurationService.api.service}/Application/ApplicationBusinessEntity/AgreementTypeId/Save?applicationBusinessEntityId=${applicationBusinessEntityId}&agreementTypeId=${agreementTypeId}`,
			{}
		);
	}

	getApplicationBusinessEntities(
		applicationId: number,
		businessEntityTypeId: BusinessEntityType
	): Observable<ApplicationBusinessEntity[]> {
		return this.http.get<ApplicationBusinessEntity[]>(
			`${this.appConfigurationService.api.businessEntity}/Application/BusinessEntity/LoadByApplicationId?applicationId=${applicationId}&businessEntityTypeId=${businessEntityTypeId}`
		);
	}

	// DEPRECATED, DELETE LATER
	getMappingTypes() {
		return this.http.get<any>(`${this.appConfigurationService.api.settings}/index/entity_mapping_types`);
	}

	saveBEMapping(mappingEntities: MappingEntity[]) {
		return this.http.post<MappingEntity[]>(
			`${this.appConfigurationService.api.settings}/Configuration/EntityMapping/Save`,
			mappingEntities
		);
	}

	loadBEMapping(entityId: number, entityTypeId: number) {
		return this.http.get<MappingEntity[]>(
			`${this.appConfigurationService.api.settings}/Configuration/EntityMapping/${entityId}/${entityTypeId}/Load`
		);
	}

	// DEPRECATED, DELETE LATER --- END
}
