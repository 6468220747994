export enum EntityType {
	Application = 1,
	BusinessRole = 2,
	BusinessEntity = 3,
	Company = 4,
	Facility = 5,
	Agreement = 6,
	Person = 7,
	AgencyUser = 8,
	ContactDetail = 9,
	Workflow = 10,
	Product = 11,
	CertificationAgreement = 12,
	ProductEvaluation = 13,
	KosherCertificate = 14,
	IngredientInfo = 15,
	KosherEvaluation = 16,
	K_ID = 17,
	Visit = 18,
	MasterIngredient = 19,
	FacilityIngredient = 20,
	MonthlyRequest = 21,
	MonReqDetail = 22,
	IngValidation = 23,
	ProductValidation = 24,
	Agency = 25,
	PrivateLabeler = 26,
	Vendor = 27,
	BatchIngredient = 28,
	// KosherCertificate = 29,
	FormulaImport = 30,
	FRBERelation = 31,
	RCBERelation = 32,
	ProductAutoApproval = 50,
	IngAutoApproval = 51,
	CntrctRelationship = 52,
	ContractInvoice = 53,
	AccountingPayment = 54,
	VisitForm = 55,
	PaperSubmission = 56,
	Communication = 57,
	CommActivity = 58,
	Project = 59,
	FacilityContract = 61,
	ProductLabel = 65,
	Transaction = 66,
	Brand = 67,
	Tag = 68,
	Note = 69,
	CompanyReferral = 70,
	ContractItem = 71,
	ContactTerm = 72,
	CompanyFacility = 73,
	Task = 75,
	TripExpense = 76,
	Manufacturer = 77,
	KosherGroup = 78,
	User = 79,
	CustomerIngredient = 80,
	BatchUkdFile = 81,
	BatchIngredientItem = 82,
	BatchProductHeader = 83,
	BatchProductIngredient = 84,
	CertificateGroup = 85,
	CertificateHeader = 86,
	GenerateARInvoice = 87,
	GenerateSystemRMC = 88,
	TemplateContract = 89,
	BusinessEntityContract = 90,
	GenerateCRMInvoice = 91,
	ContractSignatureAudit = 92,
	FormulaItem = 93,
	BatchFormulaItem = 94,
	ApplicationFormView = 95,
	VisitType = 96,
	KosherBatchProductEvaluation = 97,
	FormulaItemIngredient = 98,
	ReviewIngredient = 99,
	Amex = 103,
	Script = 106,
	ExecutionScriptHistory = 106,
	Category = 107,
	EntityCategory = 108,
	UserNote = 109,
	SystemNote = 110,
	CodeChangeUtility = 111,
	CodeChangeUtilityData = 112,
	Ukd = 113,
	NotificationWhitelist = 114,
	SecurityRole = 115,

	// USED ONLY ON UI
	Trip = 0.1,
	UKD = 0.2,
}
