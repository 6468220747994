export enum GridColumnType {
	Text = 0,
	Boolean = 1,
	Check = 2,
	TextInput = 3,
	View = 4,
	NoteInput = 5,
	HTML = 6,
	RelativeDate = 7,
	Duration = 8,
	Button = 9,
	Progress = 10,
	UtcDate = 11,
	ColoredStatus = 12,
	Attachment = 13,
	PreviewAndSendEmail = 14,
	Decimal = 15,
	TextCellRenderer = 16,
	DropdownCellRenderer = 17,
	DownloadInvoice = 18,
	GroupCheck = 19,
	ApOrArNumberList = 20,
	ReadonlyCheck = 21,
	AcumaticaApOrArNumberList = 22,
	InvoiceEntities = 23,
	InvoiceStatus = 24,
	ViewInvoiceDetails = 25,
	TryInvoiceAgain = 26,
}
