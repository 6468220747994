export enum BusinessEntityLinkType {
	Company = 1,
	Facility = 2,
	Vendor = 4,
	Agency = 8,
	Manufacturer = 16,
	PrivateLabeler = 32,
	CompanyDivision = 64,
	Contact = 128,
}
