import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-warning',
	templateUrl: './icon-warning.component.html',
	styleUrls: ['./icon-warning.component.scss'],
})
export class IconWarningComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
