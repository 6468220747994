import { TripModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const tripInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/trips/:id`,
		permissionId: TripModuleTabs.Info,
	},
	{
		name: 'Expenses',
		path: `/trips/:id/expenses`,
		permissionId: TripModuleTabs.Expenses,
	},
	{
		name: 'Itinerary',
		path: `/trips/:id/itinerary`,
		permissionId: TripModuleTabs.Itinerary,
	},
	{
		name: 'Invoices',
		path: `/trips/:id/invoices`,
		permissionId: TripModuleTabs.Invoices,
	},
	{
		name: 'Tasks',
		path: `/trips/:id/tasks`,
		permissionId: TripModuleTabs.Tasks,
	},
	{
		name: 'Payment Notes',
		path: `/trips/:id/payment-notes`,
		permissionId: TripModuleTabs.PaymentNotes,
	},
	{
		name: 'Notes',
		path: `/trips/:id/notes`,
		permissionId: TripModuleTabs.Notes,
	},
	{
		name: 'Facility Contract Notes',
		path: `/trips/:id/facility-contract-notes`,
		permissionId: TripModuleTabs.Notes,
	},
	{
		name: 'Audit Trail',
		path: `/trips/:id/audit-trail`,
	},
];
