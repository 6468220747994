import { ActiveType } from '../../enums/active-type.enum';
import { FindFilterType } from '../../enums/find-filter-type.enum';

export class SearchCriteria {
	keyword: string;
	maxCount: number;
	excludedIds: number[];

	constructor(keyword: string, maxCount: number, excludedIds: number[]) {
		this.keyword = keyword || '';
		this.maxCount = maxCount;
		this.excludedIds = excludedIds;
	}
}

export class FindSearchCriteria extends SearchCriteria {
	entityTypeId: number;
	businessEntityTypeId: number;
	filterType: FindFilterType;
	hasUser? = null;
	isParentVendor?: boolean;
	activeType?: ActiveType;
	isApplicationSearch = false;

	constructor(
		keyword: string,
		maxCount: number,
		entityTypeId: number,
		excludedIds: number[],
		businessEntityTypeId?: number,
		hasUser?: boolean,
		isParentVendor?: boolean,
		activeType?: ActiveType,
		isApplicationSearch: boolean = false
	) {
		super(keyword, maxCount, excludedIds);

		this.filterType = keyword && !Number.isNaN(+keyword) ? FindFilterType.IdEquals : FindFilterType.BeginsWith;

		this.entityTypeId = entityTypeId;
		this.businessEntityTypeId = businessEntityTypeId;
		this.hasUser = hasUser;
		this.isParentVendor = isParentVendor;
		this.activeType = activeType;
		this.isApplicationSearch = isApplicationSearch;
	}
}
