import { PermissionModule } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const submitCertificateTabs: Tab[] = [
	{
		name: 'Info',
		path: `/certificates/submit-certificate/:id`,
		permissionId: PermissionModule.KosherCertificate,
		isModule: true,
	},
	{
		name: 'Audit Trail',
		path: `/certificates/submit-certificate/:id/audit-trail`,
	},
];
