export enum PermissionModule {
	Application = 1,
	Company = 15,
	AccountingRenewal = 35,
	Facility = 39,
	PrivateLabeler = 57,
	Vendor = 68,
	Agency = 79,
	CustomerIngredients = 109,
	IngredientBatch = 123,
	ReviewIngredients = 126,
	Products = 89,
	ProductBatch = 105,
	MasterIngredient = 127,
	ChangedMI = 140,
	Visits = 145,
	Trips = 152,
	Amex = 158,
	Contact = 141,
	Task = 159,
	UKDSearch = 174,
	KosherCertificate = 106,
	KCGroup = 108,
	Users = 160,
	Roles = 165,
	Groups = 166,
	Permissions = 169,
	ProfileTemplates = 170,
	DocumentTemplates = 171,
	RulesEngine = 175,
	Scripts = 186,
	ReviewProducts = 187,
	Categories = 190,
	CodeChangeUtility = 201,
	DefaultValues = 203,
	Notifications = 204,
}

export enum RightType {
	View = 1,
	Create = 2,
	Update = 3,
	Delete = 4,
	Export = 5,
	Activate = 6,
}

export enum CompanyModuleTabs {
	Info = 16,
	Contacts = 17,
	PrivateLabelers = 18,
	Facilities = 19,
	Brands = 20,
	Alias = 21,
	Profile = 22,
	CurrentContract = 23,
	Notes = 24,
	InvoicingGroup = 25,
	FacilityContract = 26,
	Tasks = 27,
	Invoices = 28,
	Ingredients = 29,
	CompanyProducts = 30,
	KosherCertificateInfo = 31,
	KosherCertificates = 32,
	Referral = 33,
	Processes = 184,
	FinancialSnapshotInfo = 188,
	FinancialSnapshotHistory = 189,
	ProductPublication = 191,
	AutoApproval = 194,
	Guidelines = 195,
	History = 198,
}

export enum FacilityModuleTabs {
	Info = 40,
	Contacts = 41,
	Companies = 42,
	Alias = 43,
	Profile = 44,
	Notes = 45,
	VisitsAndTrips = 46,
	FacilityContract = 47,
	Tasks = 48,
	Instructions = 49,
	Guidelines = 50,
	Products = 51,
	Invoices = 52,
	KosherCertificateInfo = 53,
	Ingredients = 54,
	VisitReportQuestions = 55,
	Processes = 185,
	ProductPublication = 192,
	History = 206,
	KosherCertificates = 208,
}

export enum PrivateLabelerModuleTabs {
	Info = 58,
	Contacts = 59,
	Companies = 60,
	PLContract = 61,
	Alias = 62,
	Brands = 63,
	Notes = 64,
	Tasks = 65,
	Invoices = 66,
	KosherCertificate = 179,
	Products = 180,
	ProductPublication = 193,
	Guidelines = 197,
	KosherCertificateInfo = 200,
	History = 205,
}

export enum VendorModuleTabs {
	Info = 69,
	Contacts = 70,
	Alias = 71,
	VendorLinks = 72,
	Profile = 73,
	Notes = 74,
	Tasks = 75,
	MasterIngredients = 76,
	Agencies = 77,
	History = 207,
}

export enum AgencyModuleTabs {
	Info = 80,
	Contacts = 81,
	Alias = 82,
	Profile = 83,
	Notes = 84,
	Facilities = 85,
	Vendors = 86,
	Tasks = 87,
}

export enum CustomerIngredientsModuleTabs {
	Info = 111,
	Facilities = 112,
	Products = 113,
	Subset = 114,
	PurchaseHistory = 115,
	Guidelines = 116,
	SimilarIngredients = 117,
	BatchIngredients = 118,
	History = 119,
	Notes = 120,
	Tasks = 121,
	DependentProducts = 122,
}

export enum IngredientBatchModuleTabs {
	BatchIngredients = 124,
	Tasks = 125,
}

export enum VisitModuleTabs {
	Info = 146,
	ReportQuestions = 147,
	Products = 148,
	Notes = 149,
	Expenses = 150,
	Tasks = 151,
	PaymentNotes = 211,
}

export enum MasterIngredientTabs {
	Info = 129,
	Companies = 130,
	Facilities = 131,
	Tasks = 132,
	KosherLetters = 133,
	Categories = 134,
	History = 135,
	Notes = 136,
	ChangesHistory = 137,
	CustomerIngredients = 138,
	DependentProducts = 139,
}

export enum TripModuleTabs {
	Info = 153,
	Expenses = 154,
	Itinerary = 155,
	Invoices = 156,
	Tasks = 157,
	Notes = 209,
	PaymentNotes = 210,
}

export enum ContactModuleTabs {
	Info = 142,
	Notes = 143,
	Tasks = 144,
	History = 145,
	ContactLinks = 146,
	RabbinicalAssignments = 147,
	AccountRepAssignments = 148,
}

export enum UKDSearchModuleTabs {
	Info = 176,
	History = 177,
}

export enum ProductModuleTabs {
	Info = 90,
	Evaluations = 91,
	Formulas = 92,
	Facilities = 93,
	Processes = 94,
	Notes = 95,
	SameAs = 96,
	DependentProducts = 97,
	Categories = 98,
	KosherCertificates = 99,
	History = 100,
	ProductionHistory = 101,
	ProductLabels = 102,
	KCGroup = 103,
	Tasks = 104,
	ProductBatch = 105,
}

export enum ApplicationModuleTabs {
	Summary = 2,
	Company = 3,
	Facilities = 4,
	CompanyContract = 5,
	FacilityContract = 6,
	InitialVisit = 7,
	Contacts = 8,
	InvoicingGroups = 9,
	Tasks = 10,
	ApplicationInfo = 11,
	Referral = 12,
	PLcontract = 14,
	PrivateLabel = 173,
	Ingredients = 182,
	Products = 183,
	KosherCertificates = 181,
}

export enum UserModuleTabs {
	Info = 161,
	Security = 162,
	Restrictions = 163,
	Tasks = 164,
}

export enum UserGroupsTabs {
	Users = 167,
	Notifications = 168,
}

export enum RoleModuleTabs {
	Permissions = 221,
}
