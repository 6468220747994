import { FacilityModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const facilityInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/facilities/:id`,
		permissionId: FacilityModuleTabs.Info,
	},
	{
		name: 'Contacts',
		path: `/facilities/:id/contacts`,
		permissionId: FacilityModuleTabs.Contacts,
	},
	{
		name: 'Companies',
		path: `/facilities/:id/companies`,
		permissionId: FacilityModuleTabs.Companies,
	},
	{
		name: 'Alias',
		path: `/facilities/:id/alias`,
		permissionId: FacilityModuleTabs.Alias,
	},
	{
		name: 'Profile',
		path: `/facilities/:id/profile`,
		permissionId: FacilityModuleTabs.Profile,
	},
	{
		name: 'Visits',
		path: `/facilities/:id/visits`,
		permissionId: FacilityModuleTabs.VisitsAndTrips,
	},
	{
		name: 'Facility Contract',
		path: `/facilities/:id/contracts`,
		permissionId: FacilityModuleTabs.FacilityContract,
	},
	{
		name: 'Product Publication',
		path: `/facilities/:id/product-publication`,
		permissionId: FacilityModuleTabs.ProductPublication,
	},
	{
		name: 'Tasks',
		path: `/facilities/:id/tasks`,
		permissionId: FacilityModuleTabs.Tasks,
	},
	{
		name: 'Instructions',
		path: `/facilities/:id/instructions`,
		permissionId: FacilityModuleTabs.Instructions,
	},
	{
		name: 'Guidelines',
		path: `/facilities/:id/guidelines`,
		permissionId: FacilityModuleTabs.Guidelines,
	},
	{
		name: 'Products',
		path: `/facilities/:id/products`,
		permissionId: FacilityModuleTabs.Products,
	},
	{
		name: 'Invoices',
		path: `/facilities/:id/invoices`,
		permissionId: FacilityModuleTabs.Invoices,
	},
	{
		name: 'Processes',
		path: `/facilities/:id/processes`,
		permissionId: FacilityModuleTabs.Processes,
	},
	{
		name: 'Kosher Certificate Info',
		path: `/facilities/:id/kosher-certificate-info`,
		permissionId: FacilityModuleTabs.KosherCertificateInfo,
	},
    {
		name: 'Kosher Certificates',
		path: '/facilities/:id/kosher-certificates',
		permissionId: FacilityModuleTabs.KosherCertificates,
	},
	{
		name: 'Ingredients',
		path: `/facilities/:id/ingredients`,
		permissionId: FacilityModuleTabs.Ingredients,
	},
	{
		name: 'Visit Report Questions',
		path: `/facilities/:id/visit-report-questions`,
		permissionId: FacilityModuleTabs.VisitReportQuestions,
	},
	{
		name: 'Notes',
		path: `/facilities/:id/notes`,
		permissionId: FacilityModuleTabs.Notes,
	},
	{
		name: 'History',
		path: '/facilities/:id/history',
		permissionId: FacilityModuleTabs.History,
	},
	{
		name: 'Audit Trail',
		path: `/facilities/:id/audit-trail`,
	},
];
