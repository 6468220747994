import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { HttpRequestHeader } from 'src/app/core/enums/http-request-headers.enum';
import { HttpCustomStatus } from 'src/app/core/enums/validation/http-custom-status.enum';
import { LoginResponse } from 'src/app/core/models/auth/login.response';
import { LocalStorageService } from 'src/app/core/services/helpers/local-storage.service';
import { Login, Logout } from '../../store/actions/auth.actions';
import { AuthService } from './auth.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
	isRefreshingToken: boolean = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private store: Store<any>,
		private authService: AuthService,
		private notifierService: NotifierService,
		private localStorageService: LocalStorageService
	) {}

	/**
	 * Intercepts all HTTP requests and adds the JWT token to the request's header if the URL
	 * is a REST endpoint and not login or logout.
	 */
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const loginData = this.localStorageService.loginData;
		return next.handle(this.addTokenToRequest(request, loginData)).pipe(
			catchError(err => {
				if (err instanceof HttpErrorResponse) {
					switch ((<HttpErrorResponse>err).status) {
						case HttpCustomStatus.Unauthorized:
							if (request.url.includes('/Auth/refresh_token')) {
								this.store.dispatch(new Logout());
								throwError(err);
							}

							return request.url.includes('/Auth/token')
								? throwError(err)
								: this.handle401Error(request, next);
						case 400:
							// this.store.dispatch(new Logout());
							return throwError(err);
						case HttpCustomStatus.CorsError:
							if (!request.url.includes('/Notification/load')) {
								this.notifierService.notify('error', 'There was an error. Please contact support');
							}
							return throwError(err);
						case HttpCustomStatus.BusinessException:
							this.notifierService.notify('error', 'There was an error. Please contact support');
							return throwError(err);
						case HttpCustomStatus.RequestValidationError:
							err.error.notifications.forEach(notification => {
								this.notifierService.notify('error', notification.message);
							});
							return throwError(err);
						case HttpCustomStatus.BEExportOverflow:
							this.notifierService.notify('warning', err.error.message);
							return throwError(err);
						case HttpCustomStatus.BusinessValidationError:
							if (request.headers.get(HttpRequestHeader.DoNotToastValidationErrors) !== 'true') {
								this.notifierService.notify('error', err.error.message);
							}
							return throwError(err);
						case HttpCustomStatus.BusinessWarning:
							this.notifierService.notify('warning', err.error.message);
							return throwError(err);
					}

					return throwError(err);
				} else {
					return throwError(err);
				}
			})
		);
	}

	/**
	 * Adds the JWT token to the request's header.
	 */
	private addTokenToRequest(request: HttpRequest<any>, loginData: LoginResponse): HttpRequest<any> {
		if (loginData) {
			return request.clone({
				setHeaders: { [HttpRequestHeader.Authorization]: `Bearer ${loginData.accessToken}` },
			});
		}

		return request;
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);

			const loginData = this.localStorageService.loginData;

			if (!loginData) {
				this.isRefreshingToken = false;
				this.store.dispatch(new Logout());
				return throwError(null);
			}

			return this.authService.refreshToken(loginData?.refreshToken).pipe(
				switchMap((loginResponse: LoginResponse) => {
					if (loginResponse) {
						this.tokenSubject.next(loginResponse.accessToken);
						this.store.dispatch(new Login({ loginResponse }));
						return next.handle(this.addTokenToRequest(request, loginResponse));
					}

					this.store.dispatch(new Logout());
				}),
				catchError(err => {
					this.store.dispatch(new Logout());
					return throwError(err);
				}),
				finalize(() => {
					this.isRefreshingToken = false;
				})
			);
		} else {
			return this.tokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(token => {
					const data = new LoginResponse();
					data.accessToken = token;
					return next.handle(this.addTokenToRequest(request, data));
				}),
				catchError(err => {
					this.store.dispatch(new Logout());
					return throwError(err);
				})
			);
		}
	}
}
