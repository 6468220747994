import { UKDSearchModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const ukdInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/ukd-search/:id/info`,
		permissionId: UKDSearchModuleTabs.Info,
	},
	{
		name: 'History',
		path: `/ukd-search/:id/history`,
		permissionId: UKDSearchModuleTabs.History,
	},
];
