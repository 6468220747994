import { createSelector, select } from '@ngrx/store';

export const selectAuthState = state => state.auth;

export const isLoggedIn = createSelector(selectAuthState, auth => auth.loggedIn);

export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);

export const token = createSelector(selectAuthState, auth => (auth.loginData || {}).accessToken);

export const refreshToken = createSelector(selectAuthState, auth => (auth.loginData || {}).refreshToken);
