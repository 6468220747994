import { Action } from '@ngrx/store';
import { InactiveTabInfo } from '../../core/models/tab.model';

export enum InactiveTabsActionTypes {
	TabAdded = '[Global] Inactive Tab Added',
	TabRemoved = '[Global] Inactive Tab Removed',
}

export class InactiveTabAdded implements Action {
	readonly type = InactiveTabsActionTypes.TabAdded;

	constructor(public payload: { tab: InactiveTabInfo }) {}
}

export class InactiveTabRemoved implements Action {
	readonly type = InactiveTabsActionTypes.TabRemoved;

	constructor(public payload: { tab: InactiveTabInfo }) {}
}
export type InactiveTabsActions = InactiveTabAdded | InactiveTabRemoved;
