import { Action } from '@ngrx/store';
import { MenuItem } from '../../core/models/menu-item.model';

export enum SidenavActionTypes {
	MenuItemsRequested = '[Global] Menu items requested',
	MenuItemsLoaded = '[Global] Menu items loaded',
}

export class MenuItemsRequested implements Action {
	readonly type = SidenavActionTypes.MenuItemsRequested;
}

export class MenuItemsLoaded implements Action {
	readonly type = SidenavActionTypes.MenuItemsLoaded;

	constructor(public payload: { menuItems: MenuItem[] }) {}
}

export type SidenavActions = MenuItemsRequested | MenuItemsLoaded;
