<section class="row">
	<section class="col content">
		<div class="login" [class.loading]="isTwoFactorAuthenticationLoading">
			<img src="../../../../assets/images/logo-dark.png" class="logo" />
			<div class="welcome-text">Welcome back! Please login to your account.</div>
			<div class="form form--space-lg">
				<div class="form__element form__element--full-width">
					<app-text-input
						placeholder="Your username"
						label="Username"
						(enter)="login()"
						inputCls="text-input--block"
						size="lg"
						[field]="form.username"
					></app-text-input>
				</div>
				<div class="form__element form__element--full-width" [class.m-b-35]="!isTwoFactorAuthentication">
					<app-text-input
						placeholder="Your password"
						label="Password"
						autocomplete="new-password"
						(enter)="login()"
						inputCls="text-input--block"
						size="lg"
						[field]="form.password"
						[type]="InputType.Password"
					></app-text-input>
				</div>
				<div *ngIf="isTwoFactorAuthentication" class="form__element form__element--full-width m-b-35">
					<app-text-input
						placeholder="Your Token"
						label="Token"
						(enter)="login()"
						inputCls="text-input--block"
						size="lg"
						[field]="form.pin"
					></app-text-input>
				</div>
				<div class="form__element form__element--full-width">
					<button (click)="login()" class="btn btn--dark btn--lg" [ngClass]="{ loading: loading }">
						Login
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<a [routerLink]="['/forgot-password']" class="link">Forgot Password ?</a>
				</div>
				<div class="col col--flex col--content-end"></div>
			</div>
		</div>
		<div class="footer-links">
			<a href="#" class="link text text--light">Terms of use</a>&nbsp;
			<a href="#" class="link text text--light">Privacy policy</a>
		</div>
	</section>
</section>
