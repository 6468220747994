<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="'icon ' + iconCls" width="14" height="16" viewBox="0 0 14 16">
	<rect class="icon__path" width="14" height="3" rx="1" transform="translate(0 13)" />
	<path
		*ngIf="isDownload"
		class="icon__path"
		d="M167,319.246h-3.5v-5a1,1,0,0,0-1-1h-2a1,1,0,0,0-1,1v5H156c-.275,0-.327.145-.116.32l5.232,4.36a.625.625,0,0,0,.768,0l5.232-4.36C167.327,319.391,167.275,319.246,167,319.246Z"
		transform="translate(-154.5 -313.246)"
	></path>
	<path
		*ngIf="!isDownload"
		class="icon__path"
		d="M 12.392 7.149 L 8.892 7.149 L 8.892 2.149 C 8.892 1.597 8.445 1.149 7.892 1.149 L 5.892 1.149 C 5.34 1.149 4.892 1.597 4.892 2.149 L 4.892 7.149 L 1.392 7.149 C 1.117 7.149 1.065 7.294 1.276 7.469 L 6.508 11.829 C 6.734 12.005 7.05 12.005 7.276 11.829 L 12.508 7.469 C 12.719 7.294 12.667 7.149 12.392 7.149 Z"
		transform="matrix(-1, 0, 0, -1, 13.784, 13.11)"
	></path>
</svg>
