<ng-container *ngIf="!isChild">
	<div class="check-cell-renderer" *ngIf="customParams.editable === true && params">
		<app-checkbox
			[model]="value"
			[roundCheckbox]="true"
			[disabled]="params?.node?.data?.allowSelection === false"
			[class.icon--success-disabled]="value && params?.node?.data?.allowSelection === false"
			(modelChange)="onCheck($event)"
		></app-checkbox>
	</div>

	<div class="check-cell-renderer" *ngIf="customParams.immutable === true && params">
		<app-checkbox [model]="value" [disabled]="true" [roundCheckbox]="true"></app-checkbox>
	</div>

	<div class="check-cell-renderer" *ngIf="customParams.warning === true">
		<app-icon-check iconCls="icon--warning icon--no-align"></app-icon-check>
	</div>

	<div
		class="check-cell-renderer"
		*ngIf="customParams.disabled === true || params?.node?.data?.allowSelection === false"
	>
		<app-icon-check iconCls="icon--disabled icon--no-align"></app-icon-check>
	</div>
</ng-container>
