import { AppRouteIdentifier } from '../enums/app-route-identifier.enum';
import { PermissionModule, RightType } from '../enums/permission-module.enum';
import { AppRouteModel } from '../models/app-route.model';

/** Represents all the routes that are not tied to any specific entity, used to navigate to a specific route just by route id */
export const appSpecialRoutes: { [id: number]: AppRouteModel } = {
	[AppRouteIdentifier.MasterIngredientList]: {
		path: '/master-ingredient',
		permissionId: PermissionModule.MasterIngredient,
		permissionType: RightType.View,
		isModule: true,
	},
	[AppRouteIdentifier.UpdateMasterIngredient]: {
		path: '/master-ingredient/update',
		permissionId: PermissionModule.MasterIngredient,
		permissionType: RightType.Update,
		isModule: true,
	},
	[AppRouteIdentifier.ChangedMasterIngredient]: {
		path: '/master-ingredient/changed/master-ingredient',
		permissionId: PermissionModule.ChangedMI,
		permissionType: RightType.View,
		isModule: true,
	},
	[AppRouteIdentifier.SubmitMasterIngredient]: {
		path: '/master-ingredient/submit/master-ingredient',
		permissionId: PermissionModule.MasterIngredient,
		permissionType: RightType.Create,
		isModule: true,
	},
	[AppRouteIdentifier.ProductAnalyze]: {
		path: '/products/analyze-products/analyze',
		permissionId: PermissionModule.IngredientBatch,
		permissionType: RightType.View,
		isModule: true,
	},
};
