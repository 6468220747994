import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { SideInfoToggle, SideInfoActionTypes, SideInfoLoadedAsyncEntity } from '../actions/side-info.action';
import { map } from 'rxjs/operators';
import { ContactService } from '../../core/services/contact.service';
import { SideInfoType } from '../../core/enums/side-info-type.enum';

@Injectable()
export class SideInfoEffects {
	@Effect({ dispatch: false })
	toggle$ = this.actions$.pipe(
		ofType<SideInfoToggle>(SideInfoActionTypes.SideInfoToggle),
		map(action => {
			// logic based on type to do async call to get the entity details
			switch (action.payload.type) {
				case SideInfoType.Contact:
					this.contactSvc.infoSummary(action.payload.extraParams.contactId).subscribe(entity => {
						this.store.dispatch(
							new SideInfoLoadedAsyncEntity({
								asyncEntity: entity,
								type: action.payload.type,
							})
						);
					});
					break;
			}
		})
	);

	constructor(private actions$: Actions, private contactSvc: ContactService, private store: Store<AppState>) {}
}
