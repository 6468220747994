import { Tab } from '../../models/tab.model';

export const analyzeProductsTabs: Tab[] = [
	{
		name: 'Analyze',
		path: `/products/analyze-products/analyze`,
	},
	{
		name: 'Tasks',
		path: `/products/analyze-products/batch-related-tasks`,
	},
];
