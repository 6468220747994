import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-certificate',
	templateUrl: './icon-certificate.component.html',
	styleUrls: ['./icon-certificate.component.scss'],
})
export class IconCertificateComponent extends BaseIcon implements OnInit {
	constructor() {
        super()
    }

	ngOnInit() {}
}
