<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="'icon ' + iconCls" viewBox="0 0 16 15.913">
	<g transform="translate(-25.5 -409.244)">
		<g transform="translate(26.106 409.764)">
			<path
				class="icon__path"
				d="M34.314,413.861l-7.925,7.926a1.035,1.035,0,0,0,0,1.46l1.46,1.46a1.035,1.035,0,0,0,1.46,0l7.925-7.925Z"
				transform="translate(-26.087 -409.614)"
			/>
			<path
				class="icon__path"
				d="M40.888,411.508l-1.46-1.46a1.035,1.035,0,0,0-1.46,0L35.116,412.9l2.919,2.919,2.852-2.851A1.035,1.035,0,0,0,40.888,411.508Z"
				transform="translate(-25.795 -409.747)"
			/>
		</g>
		<path
			class="icon__path"
			d="M28.343,409.244l.671,2.067h2.172l-1.757,1.276.671,2.067-1.757-1.276-1.757,1.276.671-2.067L25.5,411.311h2.172Z"
		/>
		<path
			class="icon__path"
			d="M38.733,416.969l.427,1.319h1.385l-1.12,1.065.427,1.444-1.12-.75-1.12.845.427-1.555-1.12-1.048h1.385Z"
			transform="translate(0.369 0.25)"
		/>
		<path
			class="icon__path"
			d="M32.691,409.295l.913.675.923-.659-.36,1.075.912.673-1.133-.008-.359,1.075-.344-1.081-1.133-.008.922-.659Z"
			transform="translate(0.214 0.002)"
		/>
	</g>
</svg>
