import { Action } from '@ngrx/store';
import { FindSearchCriteria } from '../../core/models/general/search-criteria.model';

export enum UsersActionTypes {
	LoadUsers = '[Global] LoadUsers',
	LoadUsersSuccess = '[Global] LoadUsersSuccess',
}

export class LoadUsers implements Action {
	readonly type = UsersActionTypes.LoadUsers;

	constructor(public payload: { keyword: string; searchCriteria: FindSearchCriteria }) {}
}

export class LoadUsersSuccess implements Action {
	readonly type = UsersActionTypes.LoadUsersSuccess;

	constructor(public payload: { users: any[] }) {}
}

export type UsersActions = LoadUsers | LoadUsersSuccess;
