import { AgencyModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const agencyInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: '/agencies/:id',
		permissionId: AgencyModuleTabs.Info,
	},
	{
		name: 'Contacts',
		path: '/agencies/:id/contacts',
		permissionId: AgencyModuleTabs.Contacts,
	},
	{
		name: 'Alias',
		path: '/agencies/:id/alias',
		permissionId: AgencyModuleTabs.Alias,
	},
	{
		name: 'Profile',
		path: '/agencies/:id/profile',
		permissionId: AgencyModuleTabs.Profile,
	},
	{
		name: 'Notes',
		path: '/agencies/:id/notes',
		permissionId: AgencyModuleTabs.Notes,
	},
	{
		name: 'Facilities',
		path: '/agencies/:id/facilities',
		permissionId: AgencyModuleTabs.Facilities,
	},
	{
		name: 'Vendors',
		path: '/agencies/:id/vendors',
		permissionId: AgencyModuleTabs.Vendors,
	},
	{
		name: 'Tasks',
		path: '/agencies/:id/tasks',
		permissionId: AgencyModuleTabs.Tasks,
	},
	{
		name: 'Audit Trail',
		path: `/agencies/:id/audit-trail`,
	},
];
