import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-vendor',
	templateUrl: './icon-vendor.component.html',
	styleUrls: ['./icon-vendor.component.scss'],
})
export class IconVendorComponent extends BaseIcon implements OnInit {
	constructor() {
        super()
    }

	ngOnInit() {}
}
