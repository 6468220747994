import { UsersActions, UsersActionTypes } from '../actions/users.action';

export interface UsersState {
	users: any[];
	loading: boolean;
}

export const initialState: UsersState = {
	users: [],
	loading: false,
};

export function usersReducer(state = initialState, action: UsersActions): UsersState {
	switch (action.type) {
		case UsersActionTypes.LoadUsers:
			return {
				...state,
				loading: true,
			};

		case UsersActionTypes.LoadUsersSuccess:
			return {
				...state,
				loading: false,
				users: action.payload.users,
			};
		default:
			return state;
	}
}
