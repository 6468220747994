import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-view',
	templateUrl: './icon-view.component.html',
	styleUrls: ['./icon-view.component.scss'],
})
export class IconViewComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
