<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="'icon ' + iconCls" viewBox="0 0 16 16.088">
	<g transform="translate(-25.5 -57.246)">
		<g transform="translate(25.5 69.065)">
			<path
				class="icon__path"
				d="M41.273,71.691c-.273-.839-3.757-1.929-5.432-2.583A.508.508,0,0,1,35.71,69a5.288,5.288,0,0,1-2.306.507,5.163,5.163,0,0,1-2.125-.449.47.47,0,0,1-.191.114c-1.035.3-4.805,1.774-5.265,2.52a2.187,2.187,0,0,0-.281,1.579H41.488A3.9,3.9,0,0,0,41.273,71.691Z"
				transform="translate(-25.5 -69)"
			/>
		</g>
		<path
			class="icon__path"
			d="M37.49,62.523c-.444,2.942-1.839,5.03-4.107,5.03s-3.621-2.17-3.98-5.03c-.362-2.893,1.709-5.277,3.98-5.277A4.538,4.538,0,0,1,37.49,62.523Z"
			transform="translate(0.021 0)"
		/>
	</g>
</svg>
