<p
	*ngIf="params.colDef.attributeId; else defaultField"
	[ngClass]="calculateClasses()"
	[matTooltip]="params.data[params.colDef.attributeId]"
	class="text-ellipsis"
>
	{{ params.data[params.colDef.attributeId] }}
</p>
<ng-template #defaultField>
	<p [ngClass]="calculateClasses()" [matTooltip]="params.data[params.colDef.field]" class="text-ellipsis">
		{{ params.data[params.colDef.field] }}
	</p>
</ng-template>
