import { UserGroupsTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const groupInfoTabs: Tab[] = [
	{
		name: 'Users',
		path: `/settings/groups/:id/users`,
		permissionId: UserGroupsTabs.Users,
	},
	{
		name: 'Notifications',
		path: `/settings/groups/:id/notifications`,
		permissionId: UserGroupsTabs.Notifications,
	},
];
