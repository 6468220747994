import { Action } from '@ngrx/store';

export enum GlobalActionTypes {
	CurrencyLoad = '[Global] Currency load',
	CurrencyLoaded = '[Global] Currency loaded',
	CurrencyUpdate = '[Global] Currency update',
	UserLogOut = '[Global] User Logging Out',
}

export class CurrencyLoad implements Action {
	readonly type = GlobalActionTypes.CurrencyLoad;

	constructor(public payload: {}) {}
}

export class CurrencyLoaded implements Action {
	readonly type = GlobalActionTypes.CurrencyLoaded;

	constructor(public payload: { currencies: any }) {}
}

export class CurrencyUpdate implements Action {
	readonly type = GlobalActionTypes.CurrencyUpdate;

	constructor(public payload: { updatedCurrency: any }) {}
}

export class UserLogOut implements Action {
	readonly type = GlobalActionTypes.UserLogOut;
	constructor() {}
}

export type GlobalActions = CurrencyLoad | CurrencyLoaded | CurrencyUpdate | UserLogOut;
