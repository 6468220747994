<svg xmlns="http://www.w3.org/2000/svg" class="icon" [ngClass]="'icon ' + iconCls" viewBox="0 0 16 15.873">
	<path
		class="icon__path"
		d="M25.845,157.078a.248.248,0,0,0-.092.42c.139.139.364.365.5.5a.992.992,0,0,0,.615.248h13.3a.954.954,0,0,0,.581-.252l.5-.5a.248.248,0,0,0-.1-.418l-1.244-.611-4.745-2.334c-.494-.242-1.07-.541-1.281-.662a.768.768,0,0,0-.721-.012c-.186.117-.743.408-1.237.65l-4.837,2.364C26.594,156.711,26.034,156.984,25.845,157.078Z"
		transform="translate(-25.5 -153.373)"
	/>
	<path
		class="icon__path"
		d="M40.5,167.168a1,1,0,0,0-1-.922h-12a1,1,0,0,0-1,.922,1.072,1.072,0,0,0-1,1.078v1h16v-1A1.072,1.072,0,0,0,40.5,167.168Z"
		transform="translate(-25.5 -153.373)"
	/>
	<g transform="translate(1.5 5.873)">
		<path
			class="icon__path"
			d="M30,159.746a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0,0,1v4a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1v-4A.5.5,0,0,0,30,159.746Z"
			transform="translate(-27 -159.246)"
		/>
		<path
			class="icon__path"
			d="M35,159.746a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0,0,1v4a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1v-4A.5.5,0,0,0,35,159.746Z"
			transform="translate(-27 -159.246)"
		/>
		<path
			class="icon__path"
			d="M39.5,164.246v-4a.5.5,0,0,0,0-1h-2a.5.5,0,0,0,0,1v4a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1Z"
			transform="translate(-27 -159.246)"
		/>
	</g>
</svg>
