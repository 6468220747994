import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-applications',
	templateUrl: './icon-applications.component.html',
	styleUrls: ['./icon-applications.component.scss'],
})
export class IconApplicationsComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
