export enum ModuleType {
	Dashboard,
	Application,
	UKDSearch,
	Company,
	Facility,
	PrivateLabel,
	Vendors,
	Agencies,
	Products,
	KosherCertificates,
	CustomerIngredient,
	MasterIngredient,
	Contacts,
	Visits,
	Tasks,
	Settings,
	Invoices,
}
