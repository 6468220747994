import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { SidenavState, sidenavReducer } from './sidenav.reducer';
import { TabsState, tabsReducer } from './tabs.reducer';
import { PageTitleState, pageTitleReducer } from './page-title.reducer';
import { SideInfoState, sideinfoReducer } from './sideinfo.reducer';
import { applicationReducer, ApplicationState } from './application.reducer';
import { GlobalState, globalReducer } from './global.reducer';
import { UsersState, usersReducer } from './users.reducer';
import { MagicReviewPageState, magicReviewPageReducer } from './magic-review-page.reducer';
import { authReducer, AuthState } from './auth.reducer';
import { inactiveTabsReducer, InactiveTabsInfoState } from './inactive-tabs.reducer';

export interface AppState {
	sidenav: SidenavState;
	tabs: TabsState;
	pageTitle: PageTitleState;
	sideinfo: SideInfoState;
	application: ApplicationState;
	global: GlobalState;
	users: UsersState;
	magicReviewPage: MagicReviewPageState;
	auth: AuthState;
	inactiveTabs: InactiveTabsInfoState;
}

export const reducers: ActionReducerMap<AppState> = {
	sidenav: sidenavReducer,
	tabs: tabsReducer,
	pageTitle: pageTitleReducer,
	sideinfo: sideinfoReducer,
	application: applicationReducer,
	global: globalReducer,
	users: usersReducer,
	magicReviewPage: magicReviewPageReducer,
	auth: authReducer,
	inactiveTabs: inactiveTabsReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
