<svg xmlns="http://www.w3.org/2000/svg" class="icon" [ngClass]="'icon ' + iconCls" viewBox="0 0 16 16.02">
	<g transform="translate(-25.5 -281.322)">
		<path
			class="icon__path"
			d="M35.432,281.322a6.016,6.016,0,0,0-4.717,2.278,7.588,7.588,0,0,1,.79-.041,7.756,7.756,0,0,1,7.746,7.747c0,.256-.014.507-.038.757a6.035,6.035,0,0,0-3.781-10.74Z"
			transform="translate(0.031 0)"
		/>
		<path
			class="icon__path"
			d="M31.536,285.246a6.036,6.036,0,1,0,6.036,6.036A6.035,6.035,0,0,0,31.536,285.246ZM29.681,294.3a.5.5,0,0,1,0-1.006h.849V289.27h-.786a.5.5,0,0,1,0-1.006h2.264a.519.519,0,0,1,.534.487v4.543h.833a.5.5,0,1,1,0,1.006Z"
			transform="translate(0 0.024)"
		/>
	</g>
</svg>
