<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="'icon ' + iconCls" viewBox="0 0 16.1 11.5">
	<g transform="translate(-121.267 -59.469)">
		<g transform="translate(121.267 59.469)">
			<path
				class="icon__path"
				d="M129.316,59.469c-4.887,0-8.049,5.751-8.049,5.751s2.3,5.75,8.049,5.75,8.05-5.75,8.05-5.75S135.066,59.469,129.316,59.469Zm0,10.019a4.269,4.269,0,1,1,4.27-4.269A4.268,4.268,0,0,1,129.316,69.488Z"
				transform="translate(-121.267 -59.469)"
			/>
		</g>
		<path
			class="icon__path"
			d="M131.4,65.176a2.159,2.159,0,1,1-2.159-2.158A2.161,2.161,0,0,1,131.4,65.176Z"
			transform="translate(0.071 0.044)"
		/>
	</g>
</svg>
