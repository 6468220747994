import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import { LoadUsers, LoadUsersSuccess, UsersActionTypes } from 'src/app/store/actions/users.action';
import { AutocompleteService } from '../../core/services/autocomplete.service';

@Injectable()
export class UsersEffects {
	@Effect()
	loadUsers$ = this.actions$.pipe(
		ofType<LoadUsers>(UsersActionTypes.LoadUsers),
		exhaustMap(action => this.autocompleteService.findBusinessEntity(action.payload.searchCriteria)),
		map((users: any[]) => new LoadUsersSuccess({ users }))
	);

	constructor(private actions$: Actions, private autocompleteService: AutocompleteService) {}
}
