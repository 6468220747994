import * as magicReviewPage from '../reducers/magic-review-page.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectState = createFeatureSelector<magicReviewPage.MagicReviewPageState>('magicReviewPage');
export const selectMagicReviewPage = createSelector(selectState, state => state);

export const selectIngredients = createSelector(selectMagicReviewPage, state => state.ingredients);

export const safeSelectIngredientIndex = createSelector(
	selectMagicReviewPage,
	state => state.safeSelectIngredientIndex
);

export const selectMagicReviewPageLoading = createSelector(selectMagicReviewPage, state => state.loading);

export const selectSelectedIngredient = createSelector(selectMagicReviewPage, state =>
	state.ingredients.find(ingredient => ingredient.selected)
);

export const selectCurrentSelectedIndex = createSelector(selectMagicReviewPage, state => {
	const selected = state.ingredients.find(ingredient => ingredient.selected);
	return state.ingredients.indexOf(selected);
});
