import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccordionIdentifier } from '../../enums/accordion-identifier.enum';

@Injectable({
	providedIn: 'root',
})
export class AccordionCommunicationService {
	toggleAccordion$: Subject<AccordionIdentifier> = new Subject();

	toggleAccordion(id: AccordionIdentifier) {
		setTimeout(() => {
			this.toggleAccordion$.next(id);
		});
	}
}
