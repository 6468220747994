import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BatchSave, BatchFileUploadResponse, BatchIngredientItem } from '../models/ingredients/batch-ingredient.model';
import { Observable } from 'rxjs';
import { BaseModel } from '../models/base.model';
import { IngredientBatchReviewAction } from '../models/ingredients/ingredient-batch-review-action.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { ProcessBatchIngredientHeaderRequest } from '../models/ingredients/process-batch-ingredient-request.model';
import { ProcessBatchIngredientHeaderResult } from '../models/ingredients/process-batch-ingredient-response.model';
import { CompareIngredient, CompareIngredientExtended } from '../models/compare-ingredient.model';

@Injectable({
	providedIn: 'root',
})
export class IngredientBatchesService {
	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	private get uploadController() {
		return `${this.appConfigurationService.api?.service}/file`;
	}
	private get batchController() {
		return `${this.appConfigurationService.api?.service}/IngredientBatch`;
	}
	private get productBatchController() {
		return `${this.appConfigurationService.api?.product}/IngredientBatches`;
	}

	uploadBachFile(documentContainer): Observable<BatchFileUploadResponse> {
		return this.http.post<BatchFileUploadResponse>(
			`${this.uploadController}/ingredients/upload`,
			documentContainer
		);
	}

	confirmBatchItemsUpload(batchSaveData: BatchSave): Observable<BatchSave> {
		return this.http.post<BatchSave>(`${this.batchController}/confirm`, batchSaveData);
	}

	loadIngredientBatch(entityId): Observable<any> {
		return this.http.get<any>(`${this.batchController}/${entityId}/load`);
	}

	save(container: BatchSave): Observable<BatchSave> {
		return this.http.post<BatchSave>(`${this.batchController}/save`, container);
	}

	getBatchIngredientProcessStatus(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/batch_ingredient_process_status`
		);
	}

	getBatchIngredientOriginStatus(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/batch_ingredient_origin?useDefaultValue=${useDefaultValue}`
		);
	}

	getBatchIngredientPurposeStatus(useDefaultValue = false): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.settings}/index/batch_ingredient_purpose?useDefaultValue=${useDefaultValue}`
		);
	}

	compare(batchItemId: number): Observable<CompareIngredientExtended> {
		return this.http.get<CompareIngredientExtended>(`${this.batchController}/${batchItemId}/compare`);
	}

	merge(data) {
		return this.http.post(`${this.batchController}/merge`, data);
	}

	unmerge(batchIngredientItemIds: number[]) {
		return this.http.post(`${this.batchController}/un-merge`, batchIngredientItemIds);
	}

	process(request: ProcessBatchIngredientHeaderRequest): Observable<ProcessBatchIngredientHeaderResult> {
		return this.http.post<ProcessBatchIngredientHeaderResult>(`${this.batchController}/process`, request);
	}

	executeBatchItemReviewAction(action: IngredientBatchReviewAction[]): Observable<IngredientBatchReviewAction[]> {
		return this.http.post<IngredientBatchReviewAction[]>(`${this.productBatchController}/Review/Actions`, action);
	}

	assignBatchTo(ingredientBatchHeaderIds: number[], assignedToUserId: number): Observable<void> {
		return this.http.post<void>(
			`${this.appConfigurationService.api.product}/IngredientBatches/header/saveMultiple`,
			{
				ingredientBatchHeaderIds,
				assignedToUserId,
			}
		);
	}

	updateChanges(compareIngredient: CompareIngredient): Observable<BatchIngredientItem> {
		return this.http.post<BatchIngredientItem>(`${this.batchController}/updateChanges`, compareIngredient);
	}
}
