import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccordionIdentifier } from 'src/app/core/enums/accordion-identifier.enum';
import { AccordionCommunicationService } from 'src/app/core/services/communication/accordion-communication.service';
import { LocalStorageService } from 'src/app/core/services/helpers/local-storage.service';

@Directive({
	selector: '[accordionContent]',
	exportAs: 'accordionContent',
})
export class AccordionContentDirective implements OnInit, OnDestroy, AfterViewInit {
	@Input() identifier: AccordionIdentifier;
	private toggleSubscription$: Subscription;
	private opened = true;

	constructor(
		private communicationService: AccordionCommunicationService,
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private localStorageService: LocalStorageService
	) {}

	ngOnInit() {
		this.toggleSubscription$ = this.communicationService.toggleAccordion$
			.pipe(filter(id => id !== undefined && +id === +this.identifier))
			.subscribe(() => {
				this.toggle();
			});
	}

	ngAfterViewInit() {
		this.setInitialState();
	}

	ngOnDestroy() {
		if (this.toggleSubscription$) {
			this.toggleSubscription$.unsubscribe();
		}
	}

	toggle() {
		const state = this.localStorageService.accordions;
		if (this.opened) {
			this.hide();
		} else {
			this.show();
		}
	}

	setInitialState() {
		const value = this.localStorageService.accordions[this.identifier];
		if (this.identifier !== undefined && value !== undefined) {
			if (value === true) {
				this.show();
			} else {
				this.hide();
			}
		} else {
			this.show();
		}
	}

	hide() {
		const state = this.localStorageService.accordions;
		this.renderer.addClass(this.elementRef.nativeElement, 'accordion-hidden');
		this.opened = false;
		state[this.identifier] = false;
		this.localStorageService.accordions = state;
	}

	show() {
		const state = this.localStorageService.accordions;
		this.renderer.removeClass(this.elementRef.nativeElement, 'accordion-hidden');
		this.opened = true;
		state[this.identifier] = true;
		this.localStorageService.accordions = state;
	}
}
