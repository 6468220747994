import * as moment from 'moment';

export class LoginResponse {
	accessToken: string;
	tokenType?: string;
	refreshToken?: string;
	errorDescription?: string;
	expireDate?: Date;
	expireIn?: number;

	constructor(value?: LoginResponse) {
		if (value) {
			this.accessToken = value.accessToken;
			this.tokenType = value.tokenType;
			this.refreshToken = value.refreshToken;
			this.errorDescription = value.errorDescription;
			this.expireDate = value.expireDate;
			this.expireIn = value.expireIn;
		}
	}

	static isTokenValid(currentDate, expireDate): boolean {
		if (expireDate) {
			const tokenExpirationDate = moment.utc(expireDate);
			const now = moment.utc(currentDate);
			return tokenExpirationDate.isAfter(now);
		}
		return false;
	}
}
