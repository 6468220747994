import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ProductValidationResponse } from 'src/app/core/models/validation/product-validation-response.model';
import { ValidationAnswersTypes } from 'src/app/core/enums/validation/validation-answer-types.enum';
import { ValidationRequest } from '../../../core/models/validation/product-validation.model';
import { GenericValidationModal } from './generic-validation-modal.interface';
import { IndexService } from 'src/app/core/services/index.service';
import { EntityType } from 'src/app/core/enums/entity-type.enum';
import { BusinessEntityBase } from 'src/app/core/models/business-entity.model';
import { Observable } from 'rxjs';
import { RulesEngineMessageType } from 'src/app/core/enums/rules-engine-message-type.enum';
import { EntityUrlHelperService } from 'src/app/core/services/helpers/entity-url-helper.service';
import { ProductRulesEngineResponse } from 'src/app/core/models/validation/product-rules-engine-response.model';
import { BaseModel } from 'src/app/core/models/base.model';
import { ValidationMessageResponseTypes } from 'src/app/core/enums/validation/validation-message-response-types.enum';

@Component({
	selector: 'app-generic-validation-modal',
	templateUrl: './generic-validation-modal.component.html',
	styleUrls: ['./generic-validation-modal.component.scss'],
})
export class GenericValidationModalComponent
	extends SimpleModalComponent<GenericValidationModal, ValidationRequest | boolean>
	implements OnInit, GenericValidationModal
{
	title: string;
	validationResponse: ProductValidationResponse;
	rulesEngineProductValidation?: ProductRulesEngineResponse;
	productValidations: {
		entityUrl: string;
		entityObservable$: Observable<BusinessEntityBase>;
		validations: { [id: number]: string[] };
	}[] = [];

	ValidationAnswerTypes = ValidationAnswersTypes;
	RulesEngineMessageType = RulesEngineMessageType;
	MessageType = ValidationMessageResponseTypes;
	hasAdvancedPermission: boolean;
	canOverrideRulesEngine: boolean;
	rulesEngineRequest: ValidationRequest;
	loading: boolean;
	validationInfo: BaseModel[] = [];
	EntityType = EntityType;

	constructor(private indexService: IndexService, public entityUrlHelper: EntityUrlHelperService) {
		super();
	}

	ngOnInit() {
		this.canOverrideRulesEngine = this.rulesEngineProductValidation?.canOverrideRulesEngine;
		if (this.canOverrideRulesEngine) {
			if (this.hasAdvancedPermission && this.rulesEngineProductValidation?.response) {
				Object.keys(this.rulesEngineProductValidation?.response).forEach(facilityId => {
					this.productValidations.push({
						entityUrl: this.entityUrlHelper.getEntityUrl(EntityType.Facility, facilityId),
						entityObservable$: this.indexService.getEntitySummary(+facilityId, EntityType.Facility),
						validations: this.rulesEngineProductValidation.response[facilityId],
					});
				});
			} else {
				if (this.rulesEngineProductValidation) {
					Object.keys(this.rulesEngineProductValidation)?.forEach(facilityId => {
						this.productValidations.push({
							entityUrl: this.entityUrlHelper.getEntityUrl(EntityType.Facility, facilityId),
							entityObservable$: this.indexService.getEntitySummary(+facilityId, EntityType.Facility),
							validations: this.rulesEngineProductValidation[facilityId],
						});
					});
				}
			}
		}

		if (this.validationResponse?.info) {
			Object.keys(this.validationResponse?.info).forEach(facilityId => {
				const info = new BaseModel();
				info.id = +facilityId;
				info.name = this.validationResponse?.info[facilityId];
				this.validationInfo.push(info);
			});
		}
	}

	onConfirm(answerType: ValidationAnswersTypes) {
		this.validationResponse.request.answerId = answerType;
		this.result = this.validationResponse.request;
		this.close();
	}

	overrideRulesEngine(): void {
		this.result = this.rulesEngineProductValidation?.canOverrideRulesEngine;
		this.close();
	}
}
