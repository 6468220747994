import { Action } from '@ngrx/store';
import { ReviewAction } from '../../core/enums/review-action.enum';
import { IngredientBatchReviewAction } from '../../core/models/ingredients/ingredient-batch-review-action.model';
import { MagicReviewPageIngredientItem } from '../../core/models/ingredients/magic-review-page-ingredient-item.model';

export enum MagicReviewPageActionTypes {
	MagicReviewPageLoading = '[MagicReviewPage] Page loading',
	LoadMagicReviewPage = '[MagicReviewPage] Page data load',
	SetIngredients = '[MagicReviewPage] Set ingredients for review',
	SelectIngredientInQueue = '[MagicReviewPage] Select ingredient in queue',
	ExecuteBatchItemReviewAction = '[MagicReviewPage] Execute an action on the reviewed item',
	RemoveCurrentlySelectedItem = '[MagicReviewPage] Removes the currenly selected ingredient',
	SafeSelectIngredientInQueue = '[MagicReviewPage] Checks for changes in the magic review page, saves then selects a new ingredient',
}

export class LoadMagicReviewPage implements Action {
	readonly type = MagicReviewPageActionTypes.LoadMagicReviewPage;

	constructor(public payload: { batchItemIds: number[] }) {}
}

export class SetIngredients implements Action {
	readonly type = MagicReviewPageActionTypes.SetIngredients;

	constructor(public payload: { ingredients: MagicReviewPageIngredientItem[] }) {}
}

export class MagicReviewPageLoading implements Action {
	readonly type = MagicReviewPageActionTypes.MagicReviewPageLoading;

	constructor(public payload: { loading: boolean }) {}
}

export class SafeSelectIngredientInQueue implements Action {
	readonly type = MagicReviewPageActionTypes.SafeSelectIngredientInQueue;

	constructor(public payload: { selectIndex: number }) {}
}

export class SelectIngredientInQueue implements Action {
	readonly type = MagicReviewPageActionTypes.SelectIngredientInQueue;

	constructor(public payload: { next?: boolean; prev?: boolean; ingredientIndex?: number }) {}
}

export class ExecuteBatchItemReviewAction implements Action {
	readonly type = MagicReviewPageActionTypes.ExecuteBatchItemReviewAction;

	constructor(
		public payload: { items: IngredientBatchReviewAction[]; action: ReviewAction; showNotification: boolean }
	) {}
}

export class RemoveCurrentlySelectedItem implements Action {
	readonly type = MagicReviewPageActionTypes.RemoveCurrentlySelectedItem;

	constructor(public payload: {}) {}
}

export type MagicReviewPageActions =
	| LoadMagicReviewPage
	| SetIngredients
	| MagicReviewPageLoading
	| ExecuteBatchItemReviewAction
	| RemoveCurrentlySelectedItem
	| SafeSelectIngredientInQueue
	| SelectIngredientInQueue;
