import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ByteArrayHelperService {
	constructor() {}

	base64ToArrayBuffer(base64) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}

	saveByteArray(fileName, byte, mimeType, url?: boolean) {
		const blob = new Blob([byte], { type: mimeType });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		if (url) {
			return link.href;
		} else {
			link.click();
		}
	}
}
