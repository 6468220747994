import { Injectable } from '@angular/core';
import { Api } from './app-configuration';

@Injectable({
	providedIn: 'root',
})
export class AppConfigurationService {
	api: Api;
	agGridLicenseKey: string;

	static apiKeys: {
		froala: string;
		googleMapsApi: string;
		logRocket: string;
	} = {
		froala: '',
		googleMapsApi: '',
		logRocket: '',
	};
}
