import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { InputType } from 'src/app/core/enums/input-type.enum';

@Component({
	selector: 'app-text-input-checkbox-cell-renderer',
	templateUrl: './text-input-checkbox-cell-renderer.component.html',
	styleUrls: ['./text-input-checkbox-cell-renderer.component.scss'],
})
export class TextInputCheckboxCellRendererComponent implements ICellRendererAngularComp {
	public params: any;
	InputType = InputType;

	agInit(params: any): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}

	get isDisabled() {
		return (this.params.node.data.disabled || {})[this.params.colDef.colId];
	}

	pushChangesGridInput(value) {
		this.params.inputValue = value;
		this.params.api.pushChangesGridInput(this.params);
	}

	onCheck(value) {
		this.params.checkboxValue = value;
		this.params.api.pushCheckboxInput(this.params);
	}
}
