import { IngredientBatchModuleTabs } from '../../enums/permission-module.enum';

export const ingredientBatchInfoTabs = [
	{
		name: 'Batch ingredients',
		path: `ingredients/ingredient-batch/:id`,
		permissionId: IngredientBatchModuleTabs.BatchIngredients,
	},
	{
		name: 'Tasks',
		path: `ingredients/ingredient-batch/:id/tasks`,
		permissionId: IngredientBatchModuleTabs.Tasks,
	},
];
