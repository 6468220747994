import { PermissionModule } from '../../enums/permission-module.enum';

export const companyModuleTabs = [
	{
		name: 'Companies',
		path: '/companies',
		isModule: true,
		permissionId: PermissionModule.Company,
	},
	{
		name: 'Accounting Renewal',
		path: '/companies/accounting-renewal',
		isModule: true,
		permissionId: PermissionModule.AccountingRenewal,
	},
];
