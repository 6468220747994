import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellRendererCustomParams } from 'src/app/core/models/cell-renderer-custom-params.model';

@Component({
	selector: 'app-boolean-cell-renderer',
	templateUrl: './boolean-cell-renderer.component.html',
	styleUrls: ['./boolean-cell-renderer.component.scss'],
})
export class BooleanCellRendererComponent implements ICellRendererAngularComp {
	public params: any;
	isEditable: boolean;

	agInit(params: any): void {
		this.params = params;
		this.params.isEditable = false;
	}

	refresh(): boolean {
		return false;
	}

	get customParams(): CellRendererCustomParams {
		const defaultParams = new CellRendererCustomParams();
		defaultParams.disabled = true;

		if (this.params.node.customParams && Object.keys(this.params.node.customParams).length > 0) {
			const customParams = this.params.node.customParams[this.params.colDef.field];
			if (!customParams || Object.keys(customParams).length === 0) {
				return defaultParams;
			}
			return customParams;
		}
		return defaultParams;
	}

	toggleValue() {
		this.params.value = !this.params.value;
		this.params.api.onCheckboxClick(this.params.value, this.params);
	}
}
