<svg xmlns="http://www.w3.org/2000/svg" class="icon" [ngClass]="'icon ' + iconCls" viewBox="0 0 15.109 16">
	<path
		class="icon__path"
		d="M316.5,345.246h-2a1,1,0,0,0-1,1v14a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1v-15Z"
		transform="translate(-313.5 -345.246)"
	/>
	<path
		class="icon__path"
		d="M327.377,352.572c-.684-.861-1.386-1.738-1.561-1.947a.657.657,0,0,1,.036-.8c.2-.232.913-1.129,1.6-1.99l.809-1.019c.683-.862.343-1.567-.757-1.567h-10v10h10c1.1,0,1.44-.705.757-1.566Z"
		transform="translate(-313.5 -345.246)"
	/>
</svg>
