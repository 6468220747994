<div class="modal-content" [class.loading]="loading">
	<div class="modal-header">
		<button type="button" class="close" (click)="close()">&times;</button>
		<h4 class="title">{{ title }}</h4>
	</div>
	<ng-container *ngIf="rulesEngineProductValidation">
		<div class="modal-body text--center">
			<div class="m-b-25">
				<app-icon-warning class="m-b-10" [iconCls]="'icon--danger'"></app-icon-warning>
				<span class="failed">Failed!</span>
			</div>
			<div class="row col--align-center m-b-25" *ngIf="canOverrideRulesEngine && productValidations?.length > 0">
				Do you wish to override the following rules? Click on Override.
			</div>
			<div class="row col--align-center m-b-25" *ngIf="!canOverrideRulesEngine">
				You don't have the permission to override the Rules Engine.
			</div>
			<div class="underline m-b-10" *ngFor="let item of productValidations">
				<p class="row text--bold fs-16 m-b-10">
					{{ (item.entityObservable$ | async)?.name }}
					<app-icon-new-tab
						*ngIf="item.entityUrl"
						[url]="item.entityUrl"
						iconCls="icon--small icon--gray-6"
						class="m-l-5"
					></app-icon-new-tab>
				</p>

				<div class="text--danger">
					<ng-container *ngFor="let contradiction of item.validations['Contradiction']">
						<p class="m-l-5">{{ contradiction }}</p>
					</ng-container>
				</div>

				<div class="text--warning">
					<ng-container *ngFor="let warning of item.validations['Warning']">
						<p class="m-l-5">{{ warning }}</p>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn--gray-1 m-r-10" (click)="close()">Close</button>
			<button
				*ngIf="canOverrideRulesEngine && productValidations?.length > 0"
				type="button"
				class="btn btn--primary"
				(click)="overrideRulesEngine()"
			>
				Override
			</button>
		</div>
	</ng-container>

	<ng-container *ngIf="validationResponse">
		<div class="modal-body text--center fs-12">
			<div *ngIf="validationResponse.messageType == MessageType.Error" class="m-b-25">
				<app-icon-warning [iconCls]="'icon--danger'"></app-icon-warning>
				<span class="failed">Error!</span>
			</div>
			<div *ngIf="validationResponse.messageType != MessageType.Error" class="m-b-25">
				<app-icon-warning class="m-b-10" [iconCls]="'icon--danger'"></app-icon-warning>
				<span class="failed">Failed!</span>
			</div>
			<p>{{ validationResponse.message }}</p>

			<div *ngIf="validationInfo?.length > 0" class="validation-info">
				<div class="info" *ngFor="let info of validationInfo">
					<span class="name">{{ info.name }}</span>
					<app-icon-new-tab
						*ngIf="entityUrlHelper.getEntityUrl(EntityType.Facility, info.id)"
						[url]="entityUrlHelper.getEntityUrl(EntityType.Facility, info.id)"
						iconCls="icon--small icon--gray-6 cursor-pointer"
						class="m-l-5"
					></app-icon-new-tab>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button
				*ngFor="let item of validationResponse.answers | keyvalue"
				type="button"
				class="btn btn--primary m-r-10"
				[class.btn--gray-1]="
					+item.key === ValidationAnswerTypes.Cancel || +item.key === ValidationAnswerTypes.Close
				"
				(click)="onConfirm(+item.key)"
			>
				{{ item.value }}
			</button>
		</div>
	</ng-container>
</div>
