import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-visits',
	templateUrl: './icon-visits.component.html',
	styleUrls: ['./icon-visits.component.scss'],
})
export class IconVisitsComponent extends BaseIcon {
	constructor() {
		super();
	}
}
