<div class="input-wrapper">
	<app-text-input
		[type]="InputType.Number"
		[readonly]="params.value.isDisabled || isDisabled"
		[(model)]="params.value.inputValue"
		(input)="pushChangesGridInput($event.target.value)"
	></app-text-input>

	<div *ngIf="params; else valueIcon">
		<app-icon-check
			*ngIf="params?.data[params.colDef.paramSelector]"
			(click)="onCheck(false)"
			[iconCls]="'icon--success'"
		></app-icon-check>
		<app-icon-check
			*ngIf="!params?.data[params.colDef.paramSelector]"
			(click)="onCheck(true)"
			[iconCls]="'icon--light'"
		></app-icon-check>
	</div>

	<ng-template #valueIcon>
		<app-icon-check
			*ngIf="params?.data[params.colDef.paramSelector]"
			(click)="onCheck(false)"
			[iconCls]="'icon--success'"
		></app-icon-check>
		<app-icon-check
			*ngIf="!params?.data[params.colDef.paramSelector]"
			(click)="onCheck(true)"
			[iconCls]="'icon--light'"
		></app-icon-check>
	</ng-template>
</div>
