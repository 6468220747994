<div class="input-wrapper">
	<input
		[type]="InputType.Number"
		[(ngModel)]="params.value"
		(blur)="pushChangesGridBlur($event.target.value)"
		(input)="pushChangesGridInput($event)"
		(keydown)="onKeyDown($event)"
		[disabled]="isDisabled"
		[ngClass]="{ 'special-character__input': inputValueSuffix }"
	/>
	<span class="special-character" *ngIf="inputValueSuffix">{{ inputValueSuffix }}</span>
</div>
