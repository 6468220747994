import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { ActiveType } from '../enums/active-type.enum';
import { BusinessEntityLinkType } from '../enums/business-entity-link-type.enum';
import { ContactRepType } from '../enums/contact-rep-type.enum';
import { EntityType } from '../enums/entity-type.enum';
import { FindFilterType } from '../enums/find-filter-type.enum';
import { LifecycleStatus } from '../enums/lifecycle-status.enum';
import { VisitEntityType } from '../enums/visit-entity-type.enum';
import { BaseModel, BaseIndexModel, OwnerAutocomplete } from '../models/base.model';
import { BaseBusinessEntity, BusinessEntitySearchResult } from '../models/business-entity.model';
import { ContactFlat, ContactPerson } from '../models/contact.model';
import { FindSearchCriteria } from '../models/general/search-criteria.model';
import { VisitProduct } from '../models/visit-product.model';
import { VisitSearchResult } from '../models/visit.model';
import { UrlHelperService } from './helpers/url-helper.service';
import { UkdItem } from '../models/ukd-search-item.model';
import { AutocompleteModel } from '../models/autocomplete.model';
import { TaskIssueType } from '../enums/task-issue-type.enum';
import { TaskSearchResult } from '../models/task.model';

@Injectable({
	providedIn: 'root',
})
export class AutocompleteService {
	constructor(
		private http: HttpClient,
		private appConfigurationService: AppConfigurationService,
		private urlHelper: UrlHelperService
	) {}

	// SERVICE CONVENTION
	// When adding a new method if it uses the 'FindSearchCriteria' class
	// use the 'find' PREFIX when naming, for any other method that is NOT using it
	// use the 'Autocomplete' SUFFIX when naming.

	findBusinessEntityLight(searchCriteria: FindSearchCriteria): Observable<BusinessEntitySearchResult[]> {
		switch (searchCriteria.entityTypeId) {
			case EntityType.Application:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.businessEntity}/application/FindLight/`,
					searchCriteria
				);
			case EntityType.ContactDetail:
			case EntityType.Person:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.contact}/contact/FindLight/`,
					searchCriteria
				);
			case EntityType.BusinessEntity:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.businessEntity}/bussinessEntity/FindLight/`,
					searchCriteria
				);
		}
	}

	findBusinessEntity(searchCriteria: FindSearchCriteria): Observable<BusinessEntitySearchResult[]> {
		switch (searchCriteria.entityTypeId) {
			case EntityType.Application:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.businessEntity}/application/find/`,
					searchCriteria
				);
			case EntityType.BusinessEntity:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.businessEntity}/bussinessEntity/find/`,
					searchCriteria
				);
			case EntityType.Person:
				let params = {};
				if (searchCriteria.hasUser === false || searchCriteria.hasUser === true) {
					params = {
						hasUser: searchCriteria.hasUser,
					};
				}
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.contact}/contact/find`,
					searchCriteria,
					{ params: params }
				);
			case EntityType.Product:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.product}/Product/Find/`,
					searchCriteria
				);
			case EntityType.IngredientInfo:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.product}/CustomerIngredient/Find/`,
					searchCriteria
				);
			case EntityType.K_ID:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.product}/MasterIngredient/Find/`,
					searchCriteria
				);
			case EntityType.BatchIngredient:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.product}/IngredientBatches/Find/`,
					searchCriteria
				);
			case EntityType.BatchProductHeader:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.product}/ProductBatches/Find/`,
					searchCriteria
				);
			case EntityType.KosherCertificate:
				return this.http.post<BusinessEntitySearchResult[]>(
					`${this.appConfigurationService.api.product}/Certificate/Find/`,
					searchCriteria
				);
			default:
				break;
		}
	}

	findAgency(keyword: string): Observable<BusinessEntitySearchResult[]> {
		const criteria: FindSearchCriteria = new FindSearchCriteria(
			keyword,
			1000,
			EntityType.BusinessEntity,
			[],
			BusinessEntityLinkType.Agency
		);
		return this.http.post<BusinessEntitySearchResult[]>(
			`${this.appConfigurationService.api.businessEntity}/bussinessEntity/find/`,
			criteria
		);
	}

	findVendor(keyword: string, extraParams = { isParentVendor: undefined }): Observable<BusinessEntitySearchResult[]> {
		const criteria: FindSearchCriteria = new FindSearchCriteria(
			keyword,
			1000,
			EntityType.BusinessEntity,
			[],
			BusinessEntityLinkType.Vendor
		);
		criteria.isParentVendor = extraParams.isParentVendor;

		return this.http.post<BusinessEntitySearchResult[]>(
			`${this.appConfigurationService.api.businessEntity}/bussinessEntity/find/`,
			criteria
		);
	}

	findMasterIngredient(keyword: string, extraParams = { activeType: ActiveType.All }) {
		const searchCriteria = new FindSearchCriteria(
			keyword,
			50,
			EntityType.IngredientInfo,
			[],
			null,
			null,
			null,
			extraParams?.activeType
		);

		return this.http.post<BusinessEntitySearchResult[]>(
			`${this.appConfigurationService.api.product}/MasterIngredient/Find/`,
			searchCriteria
		);
	}

	findVisits(searchCriteria: FindSearchCriteria, visitEntityType?: VisitEntityType): Observable<VisitSearchResult[]> {
		return this.http.post<VisitSearchResult[]>(
			`${this.appConfigurationService.api.visit}/visit/find?visitEntityType=${visitEntityType}`,
			searchCriteria
		);
	}

	findTasks(searchCriteria: FindSearchCriteria, taskIssueType?: TaskIssueType): Observable<TaskSearchResult[]> {
		return this.http.post<TaskSearchResult[]>(
			`${this.appConfigurationService.api.task}/task/find?taskIssueType=${taskIssueType}`,
			searchCriteria
		);
	}

	ownerAutocomplete(
		keyword: string,
		extraParams = { keywordMinimumLength: null, businessEntityId: null, activeType: null }
	): Observable<OwnerAutocomplete[]> {
		const params = this.constructKeywordQueryParam(keyword, {});

		const keywordMin = this.valueOrDefault(extraParams.keywordMinimumLength, 2);
		const businessEntityId = this.valueOrDefault(extraParams.businessEntityId, null);
		const activeType = this.valueOrDefault(extraParams.activeType, ActiveType.All);

		this.urlHelper.addQueryParam(params, keywordMin, 'keywordMinimumLength', false);
		this.urlHelper.addQueryParam(params, businessEntityId, 'businessEntityId', false);
		this.urlHelper.addQueryParam(params, activeType, 'activeType', false);

		return this.http.get<OwnerAutocomplete[]>(
			`${this.appConfigurationService.api.businessEntity}/bussinessEntity/owners/findAutocomplete`,
			{ params }
		);
	}

	brandAutocomplete(
		keyword: string,
		extraParams = { companyId: null, activeType: ActiveType.All }
	): Observable<BaseIndexModel[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams?.companyId, 'companyId', false);
		this.urlHelper.addQueryParam(params, extraParams?.activeType, 'activeType', false);

		return this.http.post<BaseIndexModel[]>(
			`${this.appConfigurationService.api.product}/brand/Autocomplete`,
			null,
			{ params }
		);
	}

	contactAutocomplete(
		keyword: string,
		extraParams = { businessEntityId: null, keywordMinimumLength: 2, activeType: ActiveType.All }
	): Observable<ContactFlat[]> {
		const params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams.keywordMinimumLength, 'keywordMinimumLength', false);
		this.urlHelper.addQueryParam(params, extraParams.businessEntityId, 'businessEntityId', false);
		this.urlHelper.addQueryParam(params, extraParams.activeType, 'activeType', false);

		return this.http.get<ContactFlat[]>(`${this.appConfigurationService.api.contact}/contact/autocomplete`, {
			params,
		});
	}

	visitProductAutocomplete(
		keyword: string,
		extraParams: { facilityId: number; lifecycleStatusId: LifecycleStatus; activeType: ActiveType }
	): Observable<VisitProduct[]> {
		let params = this.constructKeywordQueryParam(keyword, {});

		this.urlHelper.addQueryParam(params, extraParams.facilityId, 'facilityId');
		this.urlHelper.addQueryParam(params, extraParams.lifecycleStatusId, 'lifecycleStatusId');
		this.urlHelper.addQueryParam(params, extraParams.activeType, 'activeType');

		return this.http.post<VisitProduct[]>(
			`${this.appConfigurationService.api.product}/Product/Facility/Autocomplete`,
			{},
			{ params }
		);
	}

	producerAutocomplete(
		keyword: string,
		extraParams = { businessEntityId: null, keywordMinimumLength: 2, activeType: ActiveType.All }
	): Observable<BaseBusinessEntity[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		if (!extraParams.businessEntityId) {
			return of([]);
		}
		this.urlHelper.addQueryParam(params, extraParams.businessEntityId, 'businessEntityId', false);
		this.urlHelper.addQueryParam(params, extraParams.keywordMinimumLength, 'keywordMinimumLength', false);
		this.urlHelper.addQueryParam(params, extraParams.activeType, 'activeType', false);

		return this.http.get<BaseBusinessEntity[]>(
			`${this.appConfigurationService.api.businessEntity}/BussinessEntity/Producer/Autocomplete`,
			{ params }
		);
	}

	usersAutocomplete(keyword = '', extraParams: { activeType?: ActiveType }): Observable<BaseModel[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams?.activeType, 'activeType', false);

		return this.http.get<BaseModel[]>(`${this.appConfigurationService.api.security}/user/autocomplete`, { params });
	}

	internalContactsAutocomplete(keyword: string, useDefaultValue = false) {
		const params = this.urlHelper.addQueryParam({}, useDefaultValue, 'useDefaultValue');
		return new Observable(observer => {
			this.http
				.get<AutocompleteModel[]>(`${this.appConfigurationService.api.service}/contact/loadinternal`, {
					params,
				})
				.subscribe(res => {
					let displayNameSearch = keyword && String(keyword).indexOf(',') > -1;
					observer.next(
						res.filter(
							contact =>
								String(contact.id).search(keyword) > -1 ||
								String(contact.name).toLowerCase().search(String(keyword).toLowerCase()) > -1 ||
								(displayNameSearch &&
									String(contact.displayName).toLowerCase().search(String(keyword).toLowerCase()) >
										-1)
						)
					);
				});
		});
	}

	fieldRepsAutocomplete(keyword: string, extraParams: { activeType?: ActiveType }): Observable<ContactPerson[]> {
		let params = this.constructKeywordQueryParam(keyword, {});

		this.urlHelper.addQueryParam(params, extraParams?.activeType, 'activeType', false);

		return this.http.get<ContactPerson[]>(
			`${this.appConfigurationService.api.contact}/contact/fieldRepsAutocomplete`,
			{ params }
		);
	}

	brandApprovedByAutocomplete(
		keyword: string,
		extraParams: { activeType?: ActiveType }
	): Observable<ContactPerson[]> {
		return this.getContactsByType(
			keyword,
			[ContactRepType.AgencyUser, ContactRepType.RabbiCoordinator],
			extraParams
		);
	}

	rabbiCoordinatorsAndAccountRepsAutocomplete(
		keyword: string,
		extraParams: { activeType?: ActiveType }
	): Observable<ContactPerson[]> {
		return this.getContactsByType(
			keyword,
			[ContactRepType.RabbiCoordinator, ContactRepType.AccountRep],
			extraParams
		);
	}

	rabbiCoordinatorsAndAgencyUsersAutocomplete(
		keyword: string,
		extraParams: { activeType?: ActiveType }
	): Observable<ContactPerson[]> {
		return this.getContactsByType(
			keyword,
			[ContactRepType.RabbiCoordinator, ContactRepType.AgencyUser],
			extraParams
		);
	}

	rabbiCoordinatorsAutocomplete(
		keyword: string,
		extraParams: { activeType?: ActiveType }
	): Observable<ContactPerson[]> {
		return this.getContactsByType(keyword, [ContactRepType.RabbiCoordinator], extraParams);
	}

	rcAndFieldRepsAutocomplete(keyword: string, extraParams: { activeType?: ActiveType }): Observable<ContactPerson[]> {
		return this.getContactsByType(keyword, [ContactRepType.RabbiCoordinator, ContactRepType.FieldRep], extraParams);
	}

	accountRepsAutocomplete(keyword: string, extraParams: { activeType?: ActiveType }): Observable<ContactPerson[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams?.activeType, 'activeType', false);

		return this.http.get<ContactPerson[]>(
			`${this.appConfigurationService.api.contact}/contact/AccountRepresentativeAutocomplete`,
			{ params }
		);
	}

	getRCAutocomplete(keyword: string, extraParams: { activeType?: ActiveType }): Observable<ContactPerson[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams?.activeType, 'activeType', false);

		return this.http.get<ContactPerson[]>(
			`${this.appConfigurationService.api.contact}/contact/rabbicoordinatorautocomplete`,
			{ params }
		);
	}

	visitContactsAutocomplete(
		keyword: string,
		extraParams: { facilityId?: number; activeType?: ActiveType; entityType?: EntityType }
	): Observable<ContactPerson[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams?.facilityId, 'facilityId', false);
		this.urlHelper.addQueryParam(params, extraParams?.activeType, 'activeType', false);
		this.urlHelper.addQueryParam(params, extraParams?.entityType, 'entityType', false);

		return this.http.get<ContactPerson[]>(
			`${this.appConfigurationService.api.service}/contact/visitcontactautocomplete`,
			{ params }
		);
	}

	certificateGroupAutocomplete(keyword: string, extraParams: { businessEntityId?: number; activeType?: ActiveType }) {
		const params = this.urlHelper.addQueryParam({}, extraParams?.businessEntityId, 'businessEntityId', false);

		return this.http.post(
			`${this.appConfigurationService.api.product}/CertificateGroup/Find`,
			{ keyword, maxCount: 100, activeType: extraParams?.activeType },
			{ params }
		);
	}

	autocompleteIdentityCode(keyword, businessEntityId = null) {
		const params = this.urlHelper.addQueryParam({}, businessEntityId, 'businessEntityId', false);
		return this.http.post(
			`${this.appConfigurationService.api.product}/CertificateGroup/IdentityCode/Find`,
			{ keyword, maxCount: 100 },
			{ params }
		);
	}

	companyAutocomplete(
		keyword: string,
		extraParams = { isForBatch: null, activeType: null }
	): Observable<BaseModel[]> {
		const params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams.isForBatch, 'isForBatch', false);
		this.urlHelper.addQueryParam(params, extraParams.activeType, 'activeType', false);

		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.businessEntity}/company/findAutocomplete`,
			{ params }
		);
	}

	visitFacilitiesAutocomplete(
		keyword: string,
		container: { fieldRepId?: number; contactRepType?: number; facilityId?: number; activeType?: ActiveType }
	): Observable<ContactPerson[]> {
		if (!container.facilityId) {
			if (!container.fieldRepId && !container.contactRepType) {
				return of([]);
			}

			let params = this.constructKeywordQueryParam(keyword, {});
			this.urlHelper.addQueryParam(params, container.fieldRepId, 'fieldRepId');
			this.urlHelper.addQueryParam(params, container.contactRepType, 'contactRepType');
			this.urlHelper.addQueryParam(params, container.activeType, 'activeType');

			return this.http.get<ContactPerson[]>(
				`${this.appConfigurationService.api.service}/facility/loadByFieldRepAutocomplete`,
				{ params }
			);
		} else {
			let params = this.constructKeywordQueryParam(container.facilityId.toString(), {});
			this.urlHelper.addQueryParam(params, container.activeType, 'activeType');

			return this.http.get<ContactPerson[]>(
				`${this.appConfigurationService.api.service}/Facility/loadAutocomplete`,
				{ params }
			);
		}
	}

	facilitiesByFieldRepAutocomplete(
		keyword: string,
		fieldRepId: number,
		contactRepType: any
	): Observable<BaseModel[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		params = this.urlHelper.addQueryParam(params, fieldRepId, 'fieldRepId');
		params = this.urlHelper.addQueryParam(params, contactRepType, 'contactRepType');

		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.service}/facility/loadByFieldRepAutocomplete`,
			{ params }
		);
	}

	ukdAutocomplete(keyword: string, params: any): Observable<BaseModel[]> {
		params = { ...params, ...this.constructKeywordQueryParam(keyword, {}) };

		return this.http.post<BaseModel[]>(`${this.appConfigurationService.api.product}/Ukd/autocomplete`, null, {
			params,
		});
	}

	ukdSearch(keyword: string): Observable<UkdItem[]> {
		const params = this.constructKeywordQueryParam(keyword, {}, 'ukd');
		const arr = [];
		return new Observable(observer => {
			this.http
				.get<UkdItem[]>(`${this.appConfigurationService.api.product}/Ukd/Load`, { params })
				.subscribe(res => {
					if (res) arr.push(res);
					observer.next(arr);
				});
		});
	}

	companiesByFieldRepAutocomplete(
		keyword: string,
		fieldRepId: number,
		facilityId: number,
		contactRepType: number,
		activeType: ActiveType
	): Observable<BaseModel[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		params = this.urlHelper.addQueryParam(params, fieldRepId, 'fieldRepId');
		params = this.urlHelper.addQueryParam(params, facilityId, 'facilityId');
		params = this.urlHelper.addQueryParam(params, contactRepType, 'contactRepType');
		params = this.urlHelper.addQueryParam(params, activeType, 'activeType');

		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.businessEntity}/company/loadByFieldRepAutocomplete`,
			{ params }
		);
	}

	companiesByFieldRepHistoryBasedAutocomplete(
		keyword: string,
		fieldRepId: number,
		facilityId: number,
		contactRepType: number,
		activeType: ActiveType,
		visitStartDate: string
	): Observable<BaseModel[]> {
		let params = this.constructKeywordQueryParam(keyword, {});
		params = this.urlHelper.addQueryParam(params, fieldRepId, 'fieldRepId');
		params = this.urlHelper.addQueryParam(params, facilityId, 'facilityId');
		params = this.urlHelper.addQueryParam(params, contactRepType, 'contactRepType');
		params = this.urlHelper.addQueryParam(params, activeType, 'activeType');
		params = this.urlHelper.addQueryParam(params, visitStartDate, 'historyDatetime');

		return this.http.get<BaseModel[]>(
			`${this.appConfigurationService.api.businessEntity}/company/LoadByFieldRepHistoryBasedAutocomplete`,
			{ params }
		);
	}

	private getContactsByType(keyword: string, types: ContactRepType[], extraParams: { activeType?: ActiveType }) {
		const params = this.constructKeywordQueryParam(keyword, {});
		this.urlHelper.addQueryParam(params, extraParams?.activeType, 'activeType', false);

		return this.http.post<ContactPerson[]>(
			`${this.appConfigurationService.api.contact}/contact/byTypeAutocomplete`,
			types,
			{ params }
		);
	}

	private constructKeywordQueryParam(keyword: string, params: { [id: string]: string | number }, key = 'keyword') {
		params[key] = keyword;
		if (keyword && !Number.isNaN(+keyword)) {
			params['filterType'] = FindFilterType.IdEquals;
		}
		return params;
	}

	private valueOrDefault(value: any, defaultValue: any) {
		if (value !== null) {
			return value;
		}
		return defaultValue;
	}
}
