import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-tasks',
	templateUrl: './icon-tasks.component.html',
	styleUrls: ['./icon-tasks.component.scss'],
})
export class IconTasksComponent extends BaseIcon implements OnInit {
	constructor() {
        super();
    }
	ngOnInit() {}
}
