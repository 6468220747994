import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
	InvoicePreviewFacility,
	InvoicePreviewDetail,
	InvoicePreviewHeader,
	InvoicePreviewHeaderRequest,
	InvoicePreviewFacilitiesRequest,
} from '../models/invoice-preview.model';
import { Invoice } from '../models/invoice.model';
import { InvoiceSummary } from '../models/invoice-summary.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { BaseModel, FindBaseModel } from '../models/base.model';
import { TransactionGridDto } from '../models/transaction-grid-dto.model';
import { AdditionalFeeInvoice } from '../models/additional-fee-invoice.model';
import { Fee } from '../models/fee.model';
import { AcumaticaLogRecord } from '../models/log-record.model';
import { JournalTransaction } from '../models/journal-transaction.model';
import { InvoiceSyncDetail } from '../models/invoice-sync-detail.model';

@Injectable({
	providedIn: 'root',
})
export class AccountingService {
	constructor(private http: HttpClient, private appConfig: AppConfigurationService) {}

	loadInvoice(id: number): Observable<Invoice> {
		return this.http.get<Invoice>(`${this.appConfig.api.service}/Accounting/Invoice/Load?invoiceHeaderId=${id}`);
	}

	getApplicationSearchTransactionGrid(
		entityId: number,
		entityTypeId: number,
		applicationTypeId: number,
		ids
	): Observable<TransactionGridDto> {
		return this.http.post<TransactionGridDto>(
			`${this.appConfig.api.service}/accounting/application/searchtransactiongrid?entityId=${entityId}&entityTypeId=${entityTypeId}&applicationTypeId=${applicationTypeId}`,
			ids
		);
	}

	getPoNumberTypes(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfig.api.settings}/index/po_number_types`);
	}

	getInvoiceSendingMethods(): Observable<BaseModel[]> {
		return this.http.get<BaseModel[]>(`${this.appConfig.api.settings}/index/invoice_sending_methods`);
	}

	getCompanySearchTransactionGrid(
		entityId: number,
		entityTypeId: number,
		invoicingGroupId: number,
		financialInvoiceType: number,
		ids
	): Observable<TransactionGridDto> {
		return this.http.post<TransactionGridDto>(
			`${this.appConfig.api.service}/accounting/company/searchtransactiongrid?entityId=${entityId}&entityTypeId=${entityTypeId}&invoicingGroupId=${invoicingGroupId}&financialInvoiceTypeId=${financialInvoiceType}`,
			ids
		);
	}

	getPrivateLabelerSearchTransactionGrid(
		entityId: number,
		entityTypeId: number,
		invoicingGroupId: number,
		ids
	): Observable<TransactionGridDto> {
		return this.http.post<TransactionGridDto>(
			`${this.appConfig.api.service}/accounting/PrivateLabeler/searchtransactiongrid?entityId=${entityId}&entityTypeId=${entityTypeId}&invoicingGroupId=${invoicingGroupId}`,
			ids
		);
	}

	generate(generateInvoicesParams): Observable<any> {
		return this.http.post<any>(
			`${this.appConfig.api.service}/accounting/generate?invoiceTypeId=${generateInvoicesParams.invoiceTypeId}`,
			generateInvoicesParams
		);
	}

	generateInvoiceBulkAccountRenewal(generateInvoicesParams): Observable<any> {
		return this.http.post<any>(
			`${this.appConfig.api.service}/accounting/accountRenewal/bulkGenerate`,
			generateInvoicesParams,
			{ headers: { 'Content-Type': 'application/json' } }
		);
	}

	generateInvoiceVisit(generateInvoicesParams): Observable<any> {
		return this.http.post<any>(
			`${this.appConfig.api.service}/accounting/visit/generate?invoiceTypeId=${generateInvoicesParams.invoiceTypeId}`,
			generateInvoicesParams
		);
	}

	generateInvoiceTrip(generateInvoicesParams): Observable<any> {
		return this.http.post<any>(
			`${this.appConfig.api.service}/accounting/Trip/Generate?invoiceTypeId=${generateInvoicesParams.invoiceTypeId}`,
			generateInvoicesParams
		);
	}

	generateInvoiceCompletedVisitsAndTrips(generateInvoicesParams): Observable<any> {
		return this.http.post<any>(
			`${this.appConfig.api.service}/accounting/CompletedVisitsAndTrips/Generate`,
			generateInvoicesParams
		);
	}

	generateCreditMemo(invoice: Invoice) {
		return this.http.post<any>(`${this.appConfig.api.service}/Accounting/CreditMemo/Generate`, invoice);
	}

	invoicePreviewDetails(generateInvoicesParams): Observable<{ [id: number]: InvoicePreviewDetail[] }> {
		return this.http.post<{ [id: number]: InvoicePreviewDetail[] }>(
			`${this.appConfig.api.service}/accounting/application/invoicePreviewDetails?invoiceTypeId=${generateInvoicesParams.invoiceTypeId}`,
			generateInvoicesParams
		);
	}

	invoicePreviewFacilities(
		invoicePreviewFacilitiesParams: InvoicePreviewFacilitiesRequest
	): Observable<{ [id: number]: InvoicePreviewFacility[] }> {
		return this.http.post<{ [id: number]: InvoicePreviewFacility[] }>(
			`${this.appConfig.api.service}/accounting/application/InvoicePreviewFacilities`,
			invoicePreviewFacilitiesParams
		);
	}

	invoicePreviewHeader(invoicePreviewHeaderParams: InvoicePreviewHeaderRequest): Observable<InvoicePreviewHeader[]> {
		return this.http.post<InvoicePreviewHeader[]>(
			`${this.appConfig.api.service}/accounting/application/invoicePreviewHeader`,
			invoicePreviewHeaderParams
		);
	}

	invoiceHeaderGroup(
		entityId: number,
		entityTypeId: number,
		ids: { businessEntityId: number; businessEntityTypeId: number }[]
	): Observable<InvoicePreviewHeader[]> {
		return this.http.post<InvoicePreviewHeader[]>(
			`${this.appConfig.api.service}/accounting/InvoiceHeaderGroup/Load?entityId=${entityId}&entityTypeId=${entityTypeId}`,
			ids
		);
	}

	export(id): Observable<any> {
		return this.http.post<any>(`${this.appConfig.api.accounting}/Accounting/Print?invoiceHeaderId=${id}`, id);
	}

	hasPrintAvailable(printIdList: number[]) {
		return this.http.post<any>(`${this.appConfig.api.accounting}/Accounting/HasPrintAvailable`, printIdList);
	}

	saveBlanketPO(invoicingGroupId: number) {
		return this.http.post<any>(
			`${this.appConfig.api.service}/Accounting/SaveBlanketPO?invoicingGroupId=${invoicingGroupId}`,
			{}
		);
	}

	getInvoiceSummary(invoiceId: number): Observable<InvoiceSummary> {
		return this.http.get<InvoiceSummary>(`${this.appConfig.api.service}/Summary/invoice?id=${invoiceId}`);
	}

	saveReplyPO(invoiceHeaderId: number, poNumber: string) {
		return this.http.post<any>(
			`${this.appConfig.api.accounting}/Accounting/SaveReplyPO?invoiceHeaderId=${invoiceHeaderId}&replyPONumber=${poNumber}`,
			{}
		);
	}

	visitTransactionGrid(visitId: number, ids): Observable<TransactionGridDto> {
		return this.http.post<TransactionGridDto>(
			`${this.appConfig.api.service}/Accounting/Visit/SearchTransactionGrid?visitId=${visitId}`,
			ids
		);
	}

	tripTransactionGrid(visitId: number): Observable<TransactionGridDto> {
		return this.http.post<TransactionGridDto>(
			`${this.appConfig.api.service}/Accounting/Trip/SearchTransactionGrid?tripId=${visitId}`,
			{}
		);
	}

	getPaymentMethods(isDefault: boolean, visibleOnExpensesPage: boolean = null): Observable<FindBaseModel[]> {
		const params = {
			useDefault: isDefault,
		};
		if (visibleOnExpensesPage !== null) {
			params['visibleOnExpensesPage'] = visibleOnExpensesPage;
		}
		return this.http.get<FindBaseModel[]>(`${this.appConfig.api.settings}/index/payment_methods`, {
			params,
		});
	}

	generateAdditionalFees(additionalFees: AdditionalFeeInvoice): Observable<AdditionalFeeInvoice> {
		return this.http.post<AdditionalFeeInvoice>(
			`${this.appConfig.api.service}/Accounting/AdditionalFees/Generate`,
			additionalFees
		);
	}

	getAgreementRegionalDerfaultFees(countryId: number): Observable<Fee[]> {
		return this.http.get<Fee[]>(`${this.appConfig.api.service}/Financial/agreement_regional_fees/${countryId}`);
	}

	getAcumaticaLogDetails(requestId: number): Observable<AcumaticaLogRecord> {
		return this.http.get<AcumaticaLogRecord>(`${this.appConfig.api.accounting}/Accounting/Logs/${requestId}`);
	}

	syncCompanyContractSnapShot(companyId: number): Observable<any> {
		return this.http.post(`${this.appConfig.api.service}/Company/Renewal/Sync`, [companyId]);
	}

	loadJournalTransactionsByFilePaymentItemIds(ids: number[]): Observable<JournalTransaction[]> {
		return this.http.post<JournalTransaction[]>(
			`${this.appConfig.api.accounting}/Accounting/Financial/JournalTransaction/LoadByFilePaymentItemIds`,
			ids
		);
	}

	loadJournalTransactionsByExpenseIds(ids: number[]): Observable<JournalTransaction[]> {
		return this.http.post<JournalTransaction[]>(
			`${this.appConfig.api.accounting}/Accounting/Financial/JournalTransaction/LoadByExpenseIds`,
			ids
		);
	}

	getSyncDetails(queueId): Observable<InvoiceSyncDetail[]> {
		return this.http.get<InvoiceSyncDetail[]>(
			`${this.appConfig.api.accounting}/Accounting/InvoiceSyncDetails/${queueId}`
		);
	}

	resyncInvoice(invoiceHeaderId): Observable<any> {
		return this.http.post(
			`${this.appConfig.api.accounting}/Accounting/ReSyncInvoice/${invoiceHeaderId}`,
			invoiceHeaderId
		);
	}
}
