import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable, Subscriber } from 'rxjs';
import { PageWithForm } from 'src/app/core/interfaces/page-with-form.interface';
import { LocalStorageService } from 'src/app/core/services/helpers/local-storage.service';
import { DataLossModalComponent } from '../modals/data-loss-modal/data-loss-modal.component';
import { GenericValidationModalComponent } from '../modals/generic-validation-modal/generic-validation-modal.component';
import { ValidationMessageResponseTypes } from 'src/app/core/enums/validation/validation-message-response-types.enum';
import { invalid } from 'moment';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class DataLossPreventionGuard implements CanDeactivate<PageWithForm> {
	constructor(private simpleModalService: SimpleModalService, private localStorageService: LocalStorageService) {}
	private saving = false;

	canDeactivate(component: PageWithForm) {
		if (this.localStorageService.loginData) {
			if (this.localStorageService.idle === true || component?.ignoreFormChanges === true) {
				return true;
			}
			if (this.saving) {
				return false;
			}
			if (component?.formInvalid !== undefined && component?.formInvalid()) {
				this.simpleModalService
					.addModal(GenericValidationModalComponent, {
						title: '',
						validationResponse: {
							info: '',
							message: 'Please, Enter all required fields',
							messageType: ValidationMessageResponseTypes.Error,
							answers: null,
							request: null,
						},
					})
					.subscribe();
				return false;
			}

			if (component?.save !== undefined && component?.formChanged !== undefined) {
				if (component.formChanged()) {
					return this.getDataLossObservable(component);
				}
				return true;
			}
			return true;
		}
		return true;
	}

	private getDataLossObservable(component: PageWithForm): Observable<boolean> {
		return new Observable(observer => {
			if (component.formChanged()) {
				return this.simpleModalService.addModal(DataLossModalComponent).subscribe(option => {
					switch (option) {
						// Cancel
						case 1:
							this.handleObs(false, observer);
							break;
						// Don't save and discard
						case 2:
							if (component.discardData !== undefined) {
								component.discardData();
							}
							let invalid = component?.formInvalid !== undefined && component?.formInvalid();
							this.handleObs(!invalid, observer);
							break;
						//  Save
						case 3:
							const saveObs = component.save();
							if (saveObs) {
								this.saving = true;
								this.setLoading(true, component);
								saveObs.subscribe(
									() => {
										this.handleObs(true, observer);
										this.setLoading(false, component);
										this.saving = false;
									},
									() => {
										this.handleObs(false, observer);
										this.setLoading(false, component);
										this.saving = false;
									}
								);
							} else {
								this.handleObs(false, observer);
							}
							break;
					}
				});
			}
		});
	}

	private handleObs(bool: boolean, observer: Subscriber<boolean>) {
		observer.next(bool);
		observer.complete();
	}

	private setLoading(value: boolean, component: PageWithForm) {
		if (component.loading !== undefined) {
			component.loading = value;
		}
	}
}
