import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-private-label',
	templateUrl: './icon-private-label.component.html',
	styleUrls: ['./icon-private-label.component.scss'],
})
export class IconPrivateLabelComponent extends BaseIcon implements OnInit {
	constructor() {
        super();
    }

	ngOnInit() {}
}
