import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-icon-download',
	templateUrl: './icon-download.component.html',
	styleUrls: ['./icon-download.component.scss'],
})
export class IconDownloadComponent implements OnInit {
	@Input() iconCls: string;
	@Input() isDownload = true;

	constructor() {}

	ngOnInit() {}
}
