import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridColumnType } from 'src/app/core/enums/grid-column-type.enum';
import { CellRendererCustomParams } from '../../../core/models/cell-renderer-custom-params.model';

@Component({
	selector: 'app-check-cell-renderer',
	templateUrl: './check-cell-renderer.component.html',
	styleUrls: ['./check-cell-renderer.component.scss'],
})
export class CheckCellRendererComponent implements ICellRendererAngularComp {
	@Output() onClick = new EventEmitter<any>();

	constructor() {}

	public params: any;
	public type = GridColumnType.Check;

	agInit(params: any): void {
		this.params = params;
	}

	get customParams(): CellRendererCustomParams {
		const defaultParams = new CellRendererCustomParams();
		defaultParams.editable = true;
		defaultParams.immutable = false;

		if (this.params.node.customParams && Object.keys(this.params.node.customParams).length > 0) {
			const customParams = this.params.node.customParams[this.params.colDef.field];
			if (!customParams || Object.keys(customParams).length === 0) {
				return defaultParams;
			}
			return customParams;
		}
		return defaultParams;
	}

	get value() {
		return this.params.node.data[this.params.colDef.field];
	}

	refresh(): boolean {
		return false;
	}

	onCheck(isChecked) {
		if (this.canBeSelected) {
			if (this.params.data.resetValuesToOriginalState) {
				isChecked = !isChecked;
			}

			this.params.data[this.params.colDef.field] = isChecked;
			this.params.api.refreshCells();
			this.params.api.onCheck(isChecked, this.params);
			this.params.api.onRowsSelectionChanged([this.params?.data]);
			setTimeout(() => {
				this.params.api.adjustColumnWidthByKey(this.params.column.colId);
			});
		}
	}

	get canBeSelected() {
		return (
			this.params?.colDef?.allowClick &&
			this.customParams.editable === true &&
			!this.customParams.warning &&
			!this.customParams.disabled &&
			(this.params?.data?.allowSelection === undefined || this.params?.data?.allowSelection === true)
		);
	}

	get checkedValue() {
		return this.params?.value;
	}
}
