import { CompanyModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';
export const companyInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: '/companies/:id',
		permissionId: CompanyModuleTabs.Info,
	},
	{
		name: 'Contacts',
		path: '/companies/:id/contacts',
		permissionId: CompanyModuleTabs.Contacts,
	},
	{
		name: 'Private Labelers',
		path: '/companies/:id/private-labelers',
		permissionId: CompanyModuleTabs.PrivateLabelers,
	},
	{
		name: 'Facilities',
		path: '/companies/:id/facilities',
		permissionId: CompanyModuleTabs.Facilities,
	},
	{
		name: 'Brands',
		path: '/companies/:id/brands',
		permissionId: CompanyModuleTabs.Brands,
	},
	{
		name: 'Alias',
		path: '/companies/:id/alias',
		permissionId: CompanyModuleTabs.Alias,
	},
	{
		name: 'Profile',
		path: '/companies/:id/profile',
		permissionId: CompanyModuleTabs.Profile,
	},
	{
		name: 'Current Contract',
		path: '/companies/:id/current-contract',
		permissionId: CompanyModuleTabs.CurrentContract,
	},
	{
		name: 'History',
		path: '/companies/:id/history',
		permissionId: CompanyModuleTabs.History,
	},
	{
		name: 'Notes',
		path: '/companies/:id/notes',
		permissionId: CompanyModuleTabs.Notes,
	},
	{
		name: 'Guidelines',
		path: `/companies/:id/guidelines`,
		permissionId: CompanyModuleTabs.Guidelines,
	},
	{
		name: 'Invoicing Group',
		path: '/companies/:id/invoicing-group',
		permissionId: CompanyModuleTabs.InvoicingGroup,
	},
	{
		name: 'Facility Contract',
		path: '/companies/:id/facility-contract',
		permissionId: CompanyModuleTabs.FacilityContract,
	},
	{
		name: 'Product Publication',
		path: '/companies/:id/product-publication',
		permissionId: CompanyModuleTabs.ProductPublication,
	},
	{
		name: 'Tasks',
		path: '/companies/:id/tasks',
		permissionId: CompanyModuleTabs.Tasks,
	},
	{
		name: 'Invoices',
		path: '/companies/:id/invoices',
		permissionId: CompanyModuleTabs.Invoices,
	},
	{
		name: 'Ingredients',
		path: '/companies/:id/ingredients',
		permissionId: CompanyModuleTabs.Ingredients,
	},
	{
		name: 'Products',
		path: '/companies/:id/company-products',
		permissionId: CompanyModuleTabs.CompanyProducts,
	},
	{
		name: 'Processes',
		path: '/companies/:id/processes',
		permissionId: CompanyModuleTabs.Processes,
	},
	{
		name: 'Kosher Certificate Info',
		path: '/companies/:id/kosher-certificate-info',
		permissionId: CompanyModuleTabs.KosherCertificateInfo,
	},
	{
		name: 'Kosher Certificates',
		path: '/companies/:id/kosher-certificates',
		permissionId: CompanyModuleTabs.KosherCertificates,
	},
	{
		name: 'Referral',
		path: '/companies/:id/referral',
		permissionId: CompanyModuleTabs.Referral,
		hidden: true,
	},
	{
		name: 'Audit Trail',
		path: `/companies/:id/audit-trail`,
	},
	{
		name: 'Auto Approval',
		path: `/companies/:id/auto-approval`,
		permissionId: CompanyModuleTabs.AutoApproval,
	},
];
