export class RegexPatterns {
	/** Strings containing numbers only */
	static readonly Numbers = new RegExp('^[0-9]*$');

	/** Strings containing uppercase letters, numbers and symbols */
	static readonly Password = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/);

	/** Strings that represents a valid IP address */
	static readonly IpAddress = new RegExp(
		'(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)'
	);

	/** Matches the row count segment in a string (** Rows) */
	static readonly GridRowCount = new RegExp(/\((.*?Rows)\)/g);

	static readonly CASPattern = new RegExp(/(^\d{2,7})-(\d{2})-(\d{1})$/);

	/** Validation for phone numbers, accepts numbers and the following symbols: . - ( ) + */
	static readonly PhoneNumber = new RegExp(/^[0-9.\-\+\(\)\s]+$/);

    /** Match white space text */
    static readonly NoWhitespace: RegExp = new RegExp("\\S");
}
