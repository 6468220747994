import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { InputType } from 'src/app/core/enums/input-type.enum';
import { Keys } from '../../../core/enums/keys.enum';
import { TextInputComponent } from '../../inputs/text-input/text-input.component';

@Component({
	selector: 'app-fee-text-input-cell-renderer',
	templateUrl: './fee-text-input-cell-renderer.component.html',
	styleUrls: ['./fee-text-input-cell-renderer.component.scss'],
})
export class FeeTextInputCellRendererComponent implements ICellRendererAngularComp, AfterViewInit {
	@ViewChild('input') input: TextInputComponent;
	public params: any;
	InputType = InputType;

	agInit(params: any): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}

	get isDisabled() {
		return (this.params.node.data?.disabled || {})[this.params.colDef.colId];
	}

	pushChangesGridInput(value) {
		this.params.value = value;
		this.params.api.pushChangesGridInput(this.params);
	}

	ngAfterViewInit() {
		if (this.params?.data.autoFocus) {
			setTimeout(() => {
				this.input.inputRef.nativeElement.focus();
			});
		}
	}

	onKeyDown(event: any): void {
		if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
			event.stopPropagation();
			return;
		}

		if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event)) {
			if (event.preventDefault) event.preventDefault();
		}
	}

	private getCharCodeFromEvent(event: any): any {
		event = event || window.event;
		return typeof event.which == 'undefined' ? event.keyCode : event.which;
	}

	private isCharNumeric(charStr: string): boolean {
		return !!/\d/.test(charStr);
	}

	private isKeyPressedNumeric(event: any): boolean {
		const charCode = this.getCharCodeFromEvent(event);
		const charStr = event.key ? event.key : String.fromCharCode(charCode);
		return this.isCharNumeric(charStr);
	}

	private deleteOrBackspace(event: any) {
		return [Keys.KEY_DELETE, Keys.KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private isLeftOrRight(event: any) {
		return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
	}

	private finishedEditingPressed(event: any) {
		const charCode = this.getCharCodeFromEvent(event);
		return charCode === Keys.KEY_ENTER || charCode === Keys.KEY_TAB;
	}
}
