import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../reducers';
import { Store } from '@ngrx/store';
import { map, exhaustMap } from 'rxjs/operators';
import { CurrencyLoad, GlobalActionTypes, CurrencyLoaded } from '../actions/global.action';
import { IndexService } from '../../core/services/index.service';

@Injectable()
export class GlobalEffects {
	@Effect()
	loadCurrencies$ = this.actions$.pipe(
		ofType<CurrencyLoad>(GlobalActionTypes.CurrencyLoad),
		exhaustMap(action => this.indexSvc.getCurrencies(false)),
		map(
			response =>
				new CurrencyLoaded({
					currencies: response,
				})
		)
	);

	constructor(private actions$: Actions, private indexSvc: IndexService, private store: Store<AppState>) {}
}
