import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'app-undo-cell-renderer',
	templateUrl: './undo-cell-renderer.component.html',
	styleUrls: ['./undo-cell-renderer.component.scss'],
})
export class UndoCellRendererComponent implements ICellRendererAngularComp {
	params: any;
	isDisabled: boolean;

	constructor() {}

	public agInit(params: ICellRendererParams): void {
		this.params = params;
		this.isDisabled = this.params.data.disabled;
	}

	public refresh(params: any): boolean {
		this.isDisabled = this.params.data.disabled;
		return this.isDisabled;
	}

	onUndoClick(): void {
		this.params.data.newValue = this.params.data.oldValue;
		this.params.data.unformattedNewValue = this.params.data.unformattedOldValue;
		this.params.data.disabled = true;
		this.params.refreshCell({
			forceRefresh: true,
		});
		this.params.colDef.onUndoClick();
	}
}
