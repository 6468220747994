import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-ingredient',
	templateUrl: './icon-ingredient.component.html',
	styleUrls: ['./icon-ingredient.component.scss'],
})
export class IconIngredientComponent extends BaseIcon implements OnInit {
	constructor() {
        super();
    }

	ngOnInit() {}
}
