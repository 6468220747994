import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
	selector: 'app-tooltip-renderer',
	templateUrl: './tooltip-renderer.component.html',
	styleUrls: ['./tooltip-renderer.component.scss'],
})
export class TooltipRendererComponent implements ITooltipAngularComp {
	params: { color: string } & ITooltipParams;
	constructor() {}

	agInit(params: { color: string } & ITooltipParams): void {
		this.params = params;
	}
}
