import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-products',
	templateUrl: './icon-products.component.html',
	styleUrls: ['./icon-products.component.scss'],
})
export class IconProductsComponent extends BaseIcon implements OnInit {
	constructor() {
        super();
    }

	ngOnInit() {}
}
