import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-search',
	templateUrl: './icon-search.component.html',
	styleUrls: ['./icon-search.component.scss'],
})
export class IconSearchComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
