import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoute } from 'src/app/core/interfaces/custom-routes.interface';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';

const routes: CustomRoute[] = [
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'logout',
		component: LogoutComponent,
	},
	{
		path: 'forgot-password',
		component: ForgotPasswordComponent,
	},
	{
		path: 'confirm-account/:key',
		component: ConfirmAccountComponent,
	},
	{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
