import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

@Component({
	selector: 'app-data-loss-modal',
	templateUrl: './data-loss-modal.component.html',
	styleUrls: ['./data-loss-modal.component.scss'],
})
export class DataLossModalComponent extends SimpleModalComponent<any, number> {
	constructor() {
		super();
	}

    onClose(buttonId: number) {
        this.result = buttonId;
        this.close();
    }
}
