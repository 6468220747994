import { Injectable } from '@angular/core';
import { PageTitle } from '../models/general/page-title.model';

@Injectable({
	providedIn: 'root',
})
export class NavigationHistoryService {
	constructor() {}

	private _navigationHistoryList: PageTitle[] = [];

	addNavigationHistory(item: PageTitle): void {
		const navHistory = this.getNavigationHistory();
		const newEntry = navHistory.find(el => el.entityId === item.entityId && el.entityTypeId === item.entityTypeId);
		if (newEntry === undefined && item.pageTitle && item.route) {
			this._navigationHistoryList.push(item);
		}
	}

	clearNavigationHistory(): void {
		this._navigationHistoryList = [];
	}

	getFilteredNavigationHistory(): PageTitle[] {
		if (this._navigationHistoryList.length <= 2) {
			return this._navigationHistoryList;
		} else {
			return this._navigationHistoryList.slice(Math.max(this._navigationHistoryList.length - 2, 1));
		}
	}

	getCurrentPage(): PageTitle {
		const navigationLenght = this.getFilteredNavigationHistory().length;
		if (navigationLenght !== 0) {
			return this._navigationHistoryList[navigationLenght - 1];
		}
		return;
	}

	getNavigationHistory(): PageTitle[] {
		return this._navigationHistoryList;
	}

	removeLastItemNavigationHistory() {
		if (this._navigationHistoryList.length !== 0) {
			this._navigationHistoryList.splice(-1);
		}
	}
}
