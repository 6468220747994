import { ApplicationModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const restaurantApplicationTabs: Tab[] = [
	{
		name: 'Summary',
		path: `:id/summary`,
		permissionId: ApplicationModuleTabs.Summary,
	},
	{
		name: 'Company',
		path: `:id/company`,
		permissionId: ApplicationModuleTabs.Company,
	},
	{
		name: 'Facilities',
		path: `:id/facilities`,
		permissionId: ApplicationModuleTabs.Facilities,
	},
	{
		name: 'Company Contract',
		path: `:id/company-contract`,
		permissionId: ApplicationModuleTabs.CompanyContract,
	},
	{
		name: 'Facility Contract',
		path: `:id/facility-contract`,
		permissionId: ApplicationModuleTabs.FacilityContract,
	},
	{
		name: 'Initial Visit',
		path: `:id/initial-visit`,
		permissionId: ApplicationModuleTabs.InitialVisit,
	},
	{
		name: 'Contacts',
		path: `:id/contacts`,
		permissionId: ApplicationModuleTabs.Contacts,
	},
	{
		name: 'Tasks',
		path: `:id/tasks`,
		permissionId: ApplicationModuleTabs.Tasks,
	},
	{
		name: 'Application Info',
		path: `:id/application-info`,
		permissionId: ApplicationModuleTabs.ApplicationInfo,
	},
	{
		name: 'Audit Trail',
		path: `:id/audit-trail`,
	},
];
