<div class="errors-container">
	<ng-container *ngIf="showFieldErrors">
		<span class="text--danger" *ngIf="errorsObject?.required">Field required</span>
		<span
			class="text--danger"
			*ngIf="errorsObject?.pattern || errorsObject?.invalidFormatting || errorsObject?.invalid"
			>Field invalid</span
		>
		<span class="text--danger" *ngIf="errorsObject?.email">Field is not a valid email</span>
		<span class="text--danger" *ngIf="errorsObject?.mustMatch">Fields must match</span>
		<span class="text--danger" *ngIf="errorsObject?.custom">{{ errorsObject?.custom.message }}</span>
		<span class="text--danger" *ngIf="errorsObject?.overlappingRanges">Field contains overlapping ranges</span>
		<span class="text--danger" *ngIf="errorsObject?.min">Min value {{ errorsObject?.min.min }}</span>
		<span class="text--danger" *ngIf="errorsObject?.max">Max value {{ errorsObject?.max.max }}</span>
		<span class="text--danger" *ngIf="errorsObject?.emailTaken">User with this email already exists</span>
		<span class="text--danger" *ngIf="errorsObject?.minDate"
			>The selected date must not be earlier than
			{{ errorsObject?.minDate.requiredDate | date : 'shortDate' }}</span
		>
		<span class="text--danger" *ngIf="errorsObject?.minlength"
			>Min length {{ errorsObject?.minlength.requiredLength }}</span
		>
		<span class="text--danger" *ngIf="errorsObject?.maxlength"
			>Max length {{ errorsObject?.maxlength.requiredLength }}</span
		>
	</ng-container>
</div>
