<div [formGroup]="noteForm" class="form">
	<div class="{{ marginCls }} note-block">
		<div class="row" *ngIf="hasHeader">
			<div class="col col--large">
				<section class="page-header p-t-10">
					<h1 class="page-header__title page-header__title--note">
						{{
							headerText ||
								activatedRoute.snapshot.data.subTitle ||
								activatedRoute.snapshot.data.pageTitle
						}}
						Notes
					</h1>
				</section>
			</div>
		</div>
		<div class="row" [class.loading]="isNoteLoading">
			<div class="col col--large">
				<div
					class="form__element form__element--full-width"
					*ngIf="varriableParam && varriableParam.length > 0"
				>
					<button
						[disabled]="readonly"
						class="btn btn--gray-1 m-l-10 m-t-14"
						(click)="appendnoteFormValue(v.name)"
						value="1"
						*ngFor="let v of varriableParam"
					>
						{{ v.description }}
					</button>
				</div>
				<div class="form__element form__element--full-width {{ marginBottomCls }}">
					<app-textarea-input
						#input
						[readonly]="readonly"
						[field]="form.value"
						[label]="showLabel ? labelText : null"
						[placeholder]="showPlaceHolder ? labelText : ''"
						[message]="notes[1] && showMessage ? notes[1].value : ''"
						[inputCls]="
							inputCls
								? inputCls
								: inputSize
								? 'text-input--full text-input text-input--text-input-size'
								: 'text-input--full'
						"
					>
					</app-textarea-input>
				</div>
			</div>
		</div>
	</div>
</div>
