import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFilterData, SearchPaging } from '../../models/general/search-paging.model';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { Criteria } from '../../models/grid/search-request.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GlobalSearchService {
	model: SearchPaging = { page: 1, pageSize: 5, fields: [] };

	public globalSearchParams = null;
	private globalSearchParamsSource = new BehaviorSubject(this.globalSearchParams);

	currentGlobalSearchParam = this.globalSearchParamsSource.asObservable();

	changeGlobalSearchParam(gridFilterParam: GlobalFilterData) {
		this.globalSearchParamsSource.next(gridFilterParam);
		setTimeout(() => {
			this.globalSearchParamsSource.next(null);
		}, 1500);
	}

	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	searchVisits(filters: Criteria) {
		const requestModel = {
			model: this.model,
			criteria: {
				keyword: filters.keyword,
				filterDropdown: filters.filterDropdown,
				filterType: filters.filterType,
			},
		};
		return this.http.post(`${this.appConfigurationService.api.visit}/Visit/Global/Search`, requestModel);
	}

	searchContacts(filters: Criteria) {
		const requestModel = {
			model: this.model,
			criteria: {
				keyword: filters.keyword,
				filterDropdown: filters.filterDropdown,
				filterType: filters.filterType,
			},
		};
		return this.http.post(`${this.appConfigurationService.api.contact}/Contact/Global/Search`, requestModel);
	}

	searchEntities(filters: Criteria) {
		const requestModel = {
			model: this.model,
			criteria: {
				keyword: filters.keyword,
				filterDropdown: filters.filterDropdown,
				filterType: filters.filterType,
			},
		};
		return this.http.post(`${this.appConfigurationService.api.product}/Entity/Global/Search`, requestModel);
	}

	searchBusinessEntities(filters: Criteria) {
		const requestModel = {
			model: this.model,
			criteria: {
				keyword: filters.keyword,
				filterDropdown: filters.filterDropdown,
				filterType: filters.filterType,
			},
		};
		return this.http.post(
			`${this.appConfigurationService.api.businessEntity}/BussinessEntity/Global/Search`,
			requestModel
		);
	}

	searchApplications(filters: Criteria) {
		const requestModel = {
			model: this.model,
			criteria: {
				keyword: filters.keyword,
				filterDropdown: filters.filterDropdown,
				filterType: filters.filterType,
			},
		};
		return this.http.post(
			`${this.appConfigurationService.api.businessEntity}/Application/Global/Search`,
			requestModel
		);
	}
}
