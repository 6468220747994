import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationService {
	cacheMap = new Map<Date | string, object>();

	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	getHebrewDate(date: Date | string) {
		const entry = this.cacheMap.get(date);
		if (!entry) {
			return this.http.get(`${this.appConfigurationService.api.settings}/configuration/hebrew?date=${date}`).pipe(
				tap(data => {
					this.cacheMap.set(date, data);
				})
			);
		}
		return of(entry);
	}

	getServerUtcDate(): Observable<{ utcDate: string }> {
		return this.http.get<{ utcDate: string }>(
			`${this.appConfigurationService.api.settings}/configuration/currentUtcDate`
		);
	}

	getEnvironment(): Observable<any> {
		return this.http.get<any>(`${this.appConfigurationService.api.settings}/configuration/environment`);
	}
}
