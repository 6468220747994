import { Action } from '@ngrx/store';

export enum SideInfoActionTypes {
	SideInfoToggle = '[Global] Side info toggle',
	SideInfoLoadedAsyncEntity = '[SideInfo] Load Async entity',
	EditRoleUpdate = '[Global] edit role',
	DeleteRoleUpdate = '[Global] delete role',
	EditNoteUpdate = '[Global] edit note',
	DeleteNoteUpdate = '[Global] delete note',
	EditGroupUpdate = '[Global] edit group',
	DeleteGroupUpdate = '[Global] delete group',
	UnlinkContactUpdate = '[Global] unlink contact',
	DeleteVisitProductUpdate = '[Global] delete visit-product',
	EditVisitProductUpdate = '[Global] edit visit-product',
}

export class SideInfoToggle implements Action {
	readonly type = SideInfoActionTypes.SideInfoToggle;

	constructor(public payload: { visible: boolean; type?: any; extraParams?: any }) {}
}

export class SideInfoLoadedAsyncEntity implements Action {
	readonly type = SideInfoActionTypes.SideInfoLoadedAsyncEntity;

	constructor(public payload: { asyncEntity: any; type: any }) {}
}

export class EditRoleAction implements Action {
	readonly type = SideInfoActionTypes.EditRoleUpdate;
	constructor(public payload: { roleDetails: any }) {}
}

export class DeleteRoleAction implements Action {
	readonly type = SideInfoActionTypes.DeleteRoleUpdate;
	constructor(public payload: { roleId: any }) {}
}

export class EditNoteAction implements Action {
	readonly type = SideInfoActionTypes.EditNoteUpdate;
	constructor(public payload: { noteDetails: any }) {}
}

export class DeleteNoteAction implements Action {
	readonly type = SideInfoActionTypes.DeleteNoteUpdate;
	constructor(public payload: { noteId: any }) {}
}

export class EditGroupAction implements Action {
	readonly type = SideInfoActionTypes.EditGroupUpdate;
	constructor(public payload: { groupDetails: any }) {}
}

export class DeleteGroupAction implements Action {
	readonly type = SideInfoActionTypes.DeleteGroupUpdate;
	constructor(public payload: { groupId: any }) {}
}

export class UnlinkContactAction implements Action {
	readonly type = SideInfoActionTypes.UnlinkContactUpdate;
	constructor(public payload: { contactId: any }) {}
}

export class DeleteVisitProductAction implements Action {
	readonly type = SideInfoActionTypes.DeleteVisitProductUpdate;
	constructor(public payload: { visitProductId: any }) {}
}

export class EditVisitProductAction implements Action {
	readonly type = SideInfoActionTypes.EditVisitProductUpdate;
	constructor(public payload: { visitProductDetails: any }) {}
}

export type SideInfoActions =
	| SideInfoToggle
	| SideInfoLoadedAsyncEntity
	| EditRoleAction
	| DeleteRoleAction
	| EditNoteAction
	| DeleteNoteAction
	| EditGroupAction
	| DeleteGroupAction
	| UnlinkContactAction
	| DeleteVisitProductAction
	| EditVisitProductAction;
