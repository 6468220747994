import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConfigurationService } from 'src/config/app-configuration.service';
import { EntityType } from '../enums/entity-type.enum';
import { NoteGroupType } from '../enums/note-group-type.enum';
import { NoteType } from '../enums/note-type.enum';
import { NoteGroup } from '../models/note-group.model';
import { Note, NoteComment, NoteCommentTasksDto } from '../models/note.model';
import { PickNote } from '../models/picknote.model';

@Injectable({
	providedIn: 'root',
})
export class NotesService {
	reloadNotesCount$ = new Subject<void>();

	constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {}

	private get noteController() {
		return `${this.appConfigurationService.api?.note}/note`;
	}

	load(noteId): Observable<Note> {
		return this.http.get<any>(`${this.noteController}/load`, { params: { noteId } });
	}

	loadByEntityType(
		entityId: number,
		entityTypeId: number,
		noteTypeId?: NoteType,
		expirationDate?: Date
	): Observable<Note[]> {
		let url = `${this.noteController}/${entityId}/${entityTypeId}/load`;

		const params = [];
		if (noteTypeId) {
			params.push(`noteTypeId=${noteTypeId}`);
		}
		if (expirationDate) {
			params.push(`expirationDate=${expirationDate}`);
		}

		if (params.length > 0) {
			url += `?${params.join('&')}`;
		}

		return this.http.get<Note[]>(url);
	}

	loadByNoteType(entityId: number, entityTypeId: number, noteTypeId: NoteType): Observable<Note[]> {
		return this.http.get<Note[]>(
			`${this.appConfigurationService.api.service}/note/${entityId}/${entityTypeId}/${noteTypeId}/load`
		);
	}

	loadListByEntityType(entityId, entityTypeId): Observable<Note[]> {
		return this.http.get<Note[]>(`${this.noteController}/${entityId}/${entityTypeId}/list`);
	}

	loadMultiple(data: { entityId: number; entityTypeId: EntityType; noteTypeId?: NoteType }[]): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.noteController}/loadMultiple`, data);
	}

	loadByEntityHistory(entityHistoryId, entityTypeId) {
		return this.http.get<any>(`${this.noteController}/${entityHistoryId}/${entityTypeId}/history`);
	}

	save(note): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.noteController}/save`, note);
	}

	saveMultiple(notes: Note[]): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.noteController}/save/multiple`, notes);
	}

	deleteNote(noteId: any) {
		return this.http.post(`${this.appConfigurationService.api.note}/Note/delete?noteId=${noteId}`, null);
	}

	getNoteSummary(id) {
		return this.http.get(`${this.appConfigurationService.api.service}/Summary/note?id=${id}`);
	}

	getPickNotes(pickNoteTypeId: number, expirationDate?: Date) {
		var url = `${this.appConfigurationService.api.note}/Note/${pickNoteTypeId}/loadNotePicklistByType`;

		if (expirationDate) {
			url += `?expirationDate=${expirationDate}`;
		}

		return this.http.get(url);
	}
	savePickNote(pickNote: PickNote): Observable<PickNote> {
		return this.http.post<PickNote>(`${this.appConfigurationService.api.service}/note/saveNotePicklist`, pickNote);
	}

	saveComment(comment: NoteComment): Observable<NoteComment> {
		return this.http.post<NoteComment>(`${this.noteController}/comment`, comment);
	}

	saveCommentBulk(noteCommentTasks: NoteCommentTasksDto) {
		return this.http.post<NoteComment>(`${this.noteController}/comment-bulk`, noteCommentTasks);
	}

	loadNoteAttachments(entityIds: any) {
		return this.http.post(`${this.appConfigurationService.api.serviceProvider}/Document/Entity/Load`, entityIds);
	}

	getNoteCount(entityId: number, entityTypeId: EntityType, noteTypeId: NoteType): Observable<number> {
		return this.http.get<number>(
			`${this.appConfigurationService.api.note}/Note/${entityId}/${entityTypeId}/${noteTypeId}/count`
		);
	}

	getNoteCountByEntityIds(entityIds: number[], entityTypeId: EntityType, noteTypeId: NoteType): Observable<number> {
		let params = new HttpParams();
		entityIds.forEach(id => {
			params = params.append('entityIds', id.toString());
		});

		return this.http.get<number>(
			`${this.appConfigurationService.api.note}/Note/${entityTypeId}/${noteTypeId}/count`,
			{ params: params }
		);
	}

	loadMultipleByNoteGroupTypeId(noteGroupTypeId: NoteGroupType, noteGroup: NoteGroup[]): Observable<Note[]> {
		return this.http.post<Note[]>(
			`${this.appConfigurationService.api.service}/Note/${noteGroupTypeId}/loadMultipleByNoteGroupTypeId`,
			noteGroup
		);
	}

	loadMultipleByNoteTypeId(noteGroup: NoteGroup[]): Observable<Note[]> {
		return this.http.post<Note[]>(`${this.appConfigurationService.api.service}/Note/loadMultiple`, noteGroup);
	}
}
