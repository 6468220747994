import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'arrayLenghtIs',
})
export class ArrayLenghtIsPipe implements PipeTransform {
	transform(value: any[], condition: '>' | '=' | '<', length: number): boolean {
		if (value) {
			switch (condition) {
				case '>':
					return value.length > +length;
				case '<':
					return value.length < +length;
				case '=':
					return value.length === +length;
			}
		}

		return null;
	}
}
