import { VendorModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const vendorInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/vendors/:id`,
		permissionId: VendorModuleTabs.Info,
	},
	{
		name: 'Contacts',
		path: `/vendors/:id/contacts`,
		permissionId: VendorModuleTabs.Contacts,
	},
	{
		name: 'Alias',
		path: `/vendors/:id/alias`,
		permissionId: VendorModuleTabs.Alias,
	},
	{
		name: 'Vendor Links',
		path: `/vendors/:id/parent-vendor-links`,
		permissionId: VendorModuleTabs.VendorLinks,
	},
	{
		name: 'Profile',
		path: `/vendors/:id/profile`,
		permissionId: VendorModuleTabs.Profile,
	},
	{
		name: 'Tasks',
		path: `/vendors/:id/tasks`,
		permissionId: VendorModuleTabs.Tasks,
	},
	{
		name: 'Master Ingredients',
		path: `/vendors/:id/master-ingredients`,
		permissionId: VendorModuleTabs.MasterIngredients,
	},
	{
		name: 'Agencies',
		path: `/vendors/:id/agencies`,
		permissionId: VendorModuleTabs.Agencies,
	},
    {
		name: 'History',
		path: `/vendors/:id/history`,
		permissionId: VendorModuleTabs.History,
	},
	{
		name: 'Notes',
		path: `/vendors/:id/notes`,
		permissionId: VendorModuleTabs.Notes,
	},
	{
		name: 'Audit Trail',
		path: `/vendors/:id/audit-trail`,
	},
];
