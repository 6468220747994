import { ContactModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const contactInfoTabs: Tab[] = [
	{
		name: 'Info',
		path: `/contacts/:id`,
		permissionId: ContactModuleTabs.Info,
	},
	{
		name: 'Contact Links',
		path: `/contacts/:id/contact-links`,
		permissionId: ContactModuleTabs.ContactLinks,
	},
	{
		name: 'Rabbinical Assignments',
		path: `/contacts/:id/rabbinical-assignments`,
		permissionId: ContactModuleTabs.RabbinicalAssignments,
	},
	{
		name: 'Account Rep Assignments',
		path: `/contacts/:id/account-rep-assignments`,
		permissionId: ContactModuleTabs.AccountRepAssignments,
	},
	{
		name: 'Tasks',
		path: `/contacts/:id/tasks`,
		permissionId: ContactModuleTabs.Tasks,
	},
	{
		name: 'Notes',
		path: `/contacts/:id/notes`,
		permissionId: ContactModuleTabs.Notes,
	},
	{
		name: 'History',
		path: '/contacts/:id/history',
		permissionId: ContactModuleTabs.History,
	},
	{
		name: 'Audit Trail',
		path: `/contacts/:id/audit-trail`,
	},
];
