import { PermissionModule } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const productModuleTabs: Tab[] = [
	{
		name: 'Products',
		path: `/products`,
		permissionId: PermissionModule.Products,
		isModule: true,
	},
	{
		name: 'Product Batch',
		path: `/products/product-batch/list`,
		permissionId: PermissionModule.ProductBatch,
		isModule: true,
	},
	{
		name: 'Review Products',
		path: `/products/review-products/list`,
		permissionId: PermissionModule.ReviewProducts,
		isModule: true,
	},
];
