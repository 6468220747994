export enum HttpCustomStatus {
	OK = 200,
	Unauthorized = 401,
	Forbidden = 403,
	BusinessException = 500,
	BusinessSuccess = 601,
	BusinessWarning = 602,
	BusinessValidationError = 603,
	RequestValidationError = 604,
	BEExportOverflow = 605,
	CorsError = 0,
}
