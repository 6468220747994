import { Pipe, PipeTransform } from '@angular/core';

// If value doesn't exist replace with needed value
@Pipe({ name: 'conditionalValuePipe' })
export class ConditionalValuePipe implements PipeTransform {
	transform(value: string, conditionalValue = '-'): string {
		value = value?.toString();

		let formattedValue = conditionalValue;

		if (value !== undefined && value !== null && value?.trim()?.length > 0) {
			formattedValue = value;
		}
		return formattedValue;
	}
}
