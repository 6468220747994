<svg class="icon" xmlns="http://www.w3.org/2000/svg" [ngClass]="'icon ' + iconCls" viewBox="0 0 14 16.885">
	<path
		class="icon__path"
		d="M68.5,189.246h3.9a1.126,1.126,0,0,0-.2-.3c-.166-.166-.926-.925-1.686-1.687l-1.7-1.7a1.051,1.051,0,0,0-.313-.2Z"
		transform="translate(-58.5 -185.361)"
	/>
	<path
		class="icon__path"
		d="M67.5,190.246h0v-5h-7a2.007,2.007,0,0,0-2,2v12a2.006,2.006,0,0,0,2,2h10a2.006,2.006,0,0,0,2-2v-9Zm-6-1.5a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H62a.5.5,0,0,1-.5-.5Zm8,9a.5.5,0,0,1-.5.5H62a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Zm0-4a.5.5,0,0,1-.5.5H62a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h7a.5.5,0,0,1,.5.5Z"
		transform="translate(-58.5 -184.361)"
	/>
</svg>
