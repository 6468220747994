<div class="input-wrapper">
	<app-text-input
		#input
		[readonly]="isDisabled || params.colDef.isDisabled"
		(input)="pushChangesGridInput($event.target.value)"
		(keydown)="onKeyDown($event)"
		[(model)]="params.value?.value || params.value"
		[type]="InputType.Number"
		[labelBadgeText]="'USD'"
	></app-text-input>
</div>
