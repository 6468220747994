export enum ValidationAnswersTypes {
	Yes = 1,
	No = 2,
	Cancel = 3,
	UpdateFormulaItems = 4,
	OnlyUpdateIngredient = 5,
	OnlyUpdateProductCode = 6,
	Close = 7,
	OpenProducer = 8,
	PublishWithout = 9,
}
