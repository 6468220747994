import { Component, Input, OnInit } from '@angular/core';
import { InputBase } from 'src/app/shared/component-bases/input-base';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends InputBase implements OnInit {
	@Input() id: number | string;
	@Input() roundCheckbox = false;
	@Input() roundCheckboxStyle: 'red' | 'green' = 'green';
	@Input() clickable = true;
	@Input() centered = false;

	constructor() {
		super();
	}

	ngOnInit() {
		this.initInput();
	}

	getCheckMarkDefaultClasses() {
		if ((this.disabled || this.readonly) && this.clickable) {
			return 'icon--no-align icon--disabled';
		}

		return 'icon--light icon--no-align';
	}
}
