<svg xmlns="http://www.w3.org/2000/svg" class="icon" [ngClass]="'icon ' + iconCls" viewBox="0 0 16 16.378">
	<g transform="translate(-25.686 -25.25)">
		<path
			class="icon__path"
			d="M39.393,35.033l-5.357-4.464a.642.642,0,0,0-.786,0l-5.357,4.464a1.245,1.245,0,0,0-.393.84v5.119a.513.513,0,0,0,.512.512H31.6V36.385a1.026,1.026,0,0,1,1.024-1.024h2.048a1.026,1.026,0,0,1,1.024,1.024V41.5h3.584a.513.513,0,0,0,.512-.512V35.873A1.245,1.245,0,0,0,39.393,35.033Z"
			transform="translate(0.043 0.124)"
		/>
		<path
			class="icon__path"
			d="M40.02,33.645l-5.948-5.1a.6.6,0,0,0-.386-.166.575.575,0,0,0-.378.158l-5.957,5.105L25.686,31.7l7.167-6.143a1.28,1.28,0,0,1,1.666,0L41.686,31.7Z"
			transform="translate(0 0)"
		/>
	</g>
</svg>
