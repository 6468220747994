import { Pipe, PipeTransform } from '@angular/core';
import { Audit } from 'src/app/core/models/audit.model';

@Pipe({
	name: 'audit',
	pure: false,
})
export class AuditPipe implements PipeTransform {
	transform(value: Audit[], key: string): Audit {
		if (value?.length > 0 && key) {
			return value.find(v => v.name.toString().toLocaleLowerCase() === key.toString().toLocaleLowerCase());
		}
	}
}
