import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'purify',
	pure: true,
})
export class PurifyPipe implements PipeTransform {
	transform(value: () => any, ...args: unknown[]): unknown {
		return value();
	}
}
