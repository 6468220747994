import { InactiveTabsActions, InactiveTabsActionTypes } from '../actions/inactive-tabs.actions';
import { InactiveTabInfo } from '../../core/models/tab.model';

export interface InactiveTabsInfoState {
	inactiveTabs: Array<InactiveTabInfo>;
}

export const initialState: InactiveTabsInfoState = {
	inactiveTabs: [],
};

export function inactiveTabsReducer(state = initialState, action: InactiveTabsActions) {
	switch (action.type) {
		case InactiveTabsActionTypes.TabAdded:
			let newTab = action.payload.tab;
			if (state.inactiveTabs.find(t => t.name == newTab.name && t.path == newTab.path)) {
				return state;
			}
			return {
				inactiveTabs: state.inactiveTabs.concat(newTab),
			};
		case InactiveTabsActionTypes.TabRemoved:
			let removedTab = action.payload.tab;
			return {
				inactiveTabs: state.inactiveTabs.filter(t => t.name != removedTab.name && t.path != removedTab.path),
			};
		default:
			return state;
	}
}
