import { PermissionModule } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const settingsModuleTabs: Tab[] = [
	{
		name: 'Users',
		path: `/settings/users`,
		permissionId: PermissionModule.Users,
		isModule: true,
	},
	{
		name: 'Roles',
		path: `/settings/roles`,
		permissionId: PermissionModule.Roles,
		isModule: true,
	},
	{
		name: 'Groups',
		path: `/settings/groups`,
		permissionId: PermissionModule.Groups,
		isModule: true,
	},
	{
		name: 'Profile Templates',
		path: `/settings/profile-templates`,
		permissionId: PermissionModule.ProfileTemplates,
		isModule: true,
	},
	{
		name: 'Document Templates',
		path: `/settings/document-templates`,
		permissionId: PermissionModule.DocumentTemplates,
		isModule: true,
	},
	{
		name: 'Managing Rules',
		path: `/settings/managing-rules`,
		permissionId: PermissionModule.RulesEngine,
		isModule: true,
	},
	{
		name: 'Scripts',
		path: `/settings/scripts`,
		permissionId: PermissionModule.Scripts,
		isModule: true,
	},
	{
		name: 'Categories',
		path: `/settings/categories`,
		permissionId: PermissionModule.Categories,
		isModule: true,
	},
	{
		name: 'Default Values',
		path: `/settings/default-values`,
		permissionId: PermissionModule.DefaultValues,
		isModule: true,
	},
	{
		name: 'Notifications',
		path: `/settings/notifications-whitelist`,
		permissionId: PermissionModule.Notifications,
		isModule: true,
	},
	{
		name: 'Acumatica Logs',
		path: `/settings/accounting-logs`,
		isModule: true,
	},
	{
		name: '2-Way Sync Logs',
		path: `/settings/two-way-sync-logs`,
		isModule: true,
	},
];
