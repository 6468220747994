<section class="row">
	<section class="col content">
		<div class="forgot-password">
			<img src="../../../../assets/images/logo-dark.png" class="logo" />
			<div *ngIf="!forgotPasswordResponse" class="welcome-text">Forgot Password</div>
			<div *ngIf="forgotPasswordResponse" class="welcome-text">
				We've sent your new password to your email: <span>{{ form.username.value }}.</span>
				<br />
				Please change your password after your first login.
			</div>
			<div *ngIf="!forgotPasswordResponse" class="form form--space-lg" [formGroup]="forgotPasswordForm">
				<div class="form__element form__element--full-width m-b-35">
					<app-text-input
						placeholder="Your username or email"
						label="Username/Email"
						inputCls="text-input--block"
						size="lg"
						[field]="form.username"
					></app-text-input>
				</div>
				<div class="form__element form__element--full-width m-b-35">
					<button
						(click)="forgotPassword()"
						class="btn btn--dark btn--lg"
						[ngClass]="{
							loading: loading
						}"
					>
						Reset Password
					</button>
				</div>
			</div>
			<div class="row">
				<div class="col text-center">
					<a [routerLink]="['/login']" class="link">Back to Login</a>
				</div>
			</div>
		</div>
		<div class="footer-links">
			<a href="#" class="link text text--light">Terms of use</a>&nbsp;
			<a href="#" class="link text text--light">Privacy policy</a>
		</div>
	</section>
</section>
