import { GlobalActions, GlobalActionTypes } from '../actions/global.action';

export interface GlobalState {
	currencies: any;
	updatedCurrency: any;
	isUserLoggingOut: boolean;
}

export const initialState: GlobalState = {
	currencies: undefined,
	updatedCurrency: undefined,
	isUserLoggingOut: false,
};

export function globalReducer(state = initialState, action: GlobalActions): GlobalState {
	switch (action.type) {
		case GlobalActionTypes.CurrencyLoaded:
			return {
				...state,
				currencies: action.payload.currencies,
				isUserLoggingOut: false,
			};

		case GlobalActionTypes.CurrencyUpdate:
			return {
				...state,
				updatedCurrency: action.payload.updatedCurrency,
			};
		case GlobalActionTypes.UserLogOut:
			return {
				...state,
				isUserLoggingOut: true,
			};
		default:
			return state;
	}
}
