import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { map } from 'rxjs/operators';
import { MustMatch } from 'src/app/shared/validators/must-match.validator';
import { AuthService } from '../auth.service';
import { InputType } from 'src/app/core/enums/input-type.enum';
import { RegexPatterns } from 'src/app/core/enums/validation/regex-patterns.enum';

@Component({
	selector: 'app-confirm-account',
	templateUrl: './confirm-account.component.html',
	styleUrls: ['./confirm-account.component.scss'],
})
export class ConfirmAccountComponent implements OnInit {
	confirmForm: FormGroup;
	key: string;
	buttonLoading = false;
	pageLoading = false;
	isRegistrationCodeAvailable: boolean;
	InputType = InputType;

	constructor(
		private authService: AuthService,
		private fb: FormBuilder,
		private router: Router,
		private notifier: NotifierService,
		private activatedRoute: ActivatedRoute
	) {
		this.confirmForm = this.fb.group(
			{
				password: ['', [Validators.required, Validators.pattern(RegexPatterns.Password)]],
				confirmPassword: ['', [Validators.required, Validators.pattern(RegexPatterns.Password)]],
			},
			{
				validator: MustMatch('password', 'confirmPassword'),
			}
		);
	}

	ngOnInit() {
		this.activatedRoute.paramMap.pipe(map((paramsMap: any) => paramsMap.params)).subscribe(params => {
			this.key = params.key;
			this.pageLoading = true;

			this.authService.checkRegistrationCode(this.key).subscribe(
				response => {
					this.isRegistrationCodeAvailable = response;
					this.pageLoading = false;
				},
				() => {
					this.pageLoading = false;
				}
			);
		});
	}
	get form() {
		return this.confirmForm.controls;
	}

	confirm() {
		if (this.confirmForm.invalid) {
			(<any>Object).values(this.form).forEach(control => {
				control.markAsDirty();
			});
			return;
		}

		this.buttonLoading = true;

		let confirmAccountContainer = {
			key: this.key,
			password: this.confirmForm.value.password,
			confirmPassword: this.confirmForm.value.confirmPassword,
		};

		this.authService.confirmAccount(confirmAccountContainer).subscribe(
			() => {
				this.buttonLoading = false;
				this.notifier.notify('success', 'Your account was confirmed. Please login to continue');
				this.router.navigateByUrl('/login');
			},
			(error: HttpErrorResponse) => {
				switch (error.status) {
					case 604:
						error.error.notifications.forEach(err => {
							this.notifier.notify('error', err.message);
						});
						break;
					case 500:
						this.notifier.notify('error', 'There was a problem when trying to save your changes');
						break;
					case 602:
						this.notifier.notify('error', error.error.message);
						break;
					case 603:
						this.notifier.notify('error', error.error.message);
						break;
				}
				this.buttonLoading = false;
			}
		);
	}
}
