export class CustomParamsDictionary {
	/** The id is the grid columns key */
	[id: string]: CellRendererCustomParams;
}

export class CellRendererCustomParams {
	disabled: boolean;
	warning: boolean;
	immutable: boolean;
	editable: boolean;
}
