import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InputType } from 'src/app/core/enums/input-type.enum';
import { InputBase } from '../../component-bases/input-base';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent extends InputBase implements OnInit, OnChanges {
	@Input() type: InputType = InputType.Text;
	@Input() focusOnPageEnter = false;

	@Input() url: string;
	@Input() href: string;
	@Input() withSearch = false;
	@Input() showRequiredStar = true;
	@Input() autocomplete = 'off';
	@Input() badgeText: string;
	@Input() dangerAdditionalInfo: string;
	@Input() labelBadgeText: string;
	@Input() openInNewTabLink: string;
	@Input() inputValueSufix: string;
	@Input() textAlignLeft = false;
	@Input() showInfoTooltip = false;
	@Input() wheelEvent = false;
	@Input() clearable = false;
	@Input() invalidData = false;

	@Input() minDecimals = 2;
	@Input() maxDecimals = 4;
	@Input() precision: number;
	@Input() step = '0.0001';

	@Output() badgeClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() searchIconClicked: EventEmitter<any> = new EventEmitter<any>();
	@Output() labelBadgeClicked: EventEmitter<any> = new EventEmitter<any>();

	initialType: InputType;
	InputType = InputType;
	isFieldDisabled = false;

	tooltip = `At least a lower case letter
                A capital(uppercase) letter
                A number
                A special character
                Minimum 8 characters.`;

	constructor() {
		super();
	}

	ngOnInit() {
		this.initInput();
		this.initialType = this.type;
		setTimeout(() => {
			this.parseNumbers();
			if (this.precision) {
				this.field.patchValue(+parseFloat(this.field.value).toPrecision(this.precision));
			}
		});
	}

	get cssClass() {
		let sizeCls = '';
		switch (this.size) {
			case 'lg':
				sizeCls = ' text-input--lg';
				break;
		}

		return 'text-input ' + (this.inputCls || '') + sizeCls;
	}

	onLabelClick() {
		this.inputRef.nativeElement.focus();
	}

	parseNumbers() {
		if (this.type === InputType.Number) {
			if (this.field.value || this.field.value === 0) {
				if (this.maxDecimals || this.maxDecimals === 0) {
					let numberValue = parseFloat(this.field.value);

					if ((this.minDecimals || this.minDecimals === 0) &&
						numberValue % 1 == 0) {
						this.field.patchValue(numberValue.toFixed(this.minDecimals));
					}
					else {
						this.field.patchValue(numberValue.toFixed(this.maxDecimals));
					}


				} else {
					this.field.patchValue(parseFloat(this.field.value));
				}
				this.emitChange();
			}
		}
	}

	onChangeType() {
		if (this.type === InputType.Password) {
			this.type = InputType.Text;
		} else {
			this.type = InputType.Password;
		}
	}

	search(value: string): void {
		const parentDisabled = this.isParentFieldSetDisabled(this.inputRef.nativeElement);
		if (!this.disabled && !parentDisabled) {
			this.inputRef?.nativeElement?.focus();
			this.searchIconClicked.emit(value);
		}
	}

	ngOnChanges() {
		this.isFieldDisabled = this.disabled || this.isParentFieldSetDisabled(this.inputRef?.nativeElement);
	}

	clear() {
		this.field.markAsDirty();
		this.field.markAsTouched();
		this.field.patchValue(null);
		this.emitChange();
	}
}
