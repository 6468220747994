export enum TabsType {
	None,
	CompanyModule,
	CompanyInfo,
	FacilityInfo,
	PrivateLabelerInfo,
	ContactInfo,
	VendorInfo,
	AgencyInfo,
	UserSettingsInfo,
	CurrentUserInfo,
	VisitsModule,
	VisitsInfo,
	TripsInfo,
	SettingsModule,
	GroupInfo,
	CustomerIngredientModule,
	MasterIngredientInfo,
	CustomerIngredientInfo,
	SubmitCustomerIngredient,
	ProductInfo,
	ProductsModule,
	ContractCleanup,
	IngredientBatchInfo,
	AccountingRenewal,
	ProductBatch,
	MasterIngredientModule,
	KosherCertificateModule,
	Dashboard,
	CompanyApplicationInfo,
	FacilityApplicationInfo,
	RestaurantApplicationInfo,
	PrivateLabelerApplicationInfo,
	UkdSearchInfo,
	AnalyzeProductInfo,
	TaskInfo,
	SubmitCertificateInfo,
	CustomerIngredientCodeChangesUtilityModuleTabs,
	RoleSettingsInfo,
}
