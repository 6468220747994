<p>{{ params.displayName }}</p>
<div class="checkbox-wrapper">
	<div *ngIf="params; else valueIcon">
		<app-icon-check
			*ngIf="params.column.colDef.isChecked"
			(click)="onCheck(false)"
			[iconCls]="'icon--success'"
		></app-icon-check>
		<app-icon-check
			*ngIf="!params.column.colDef.isChecked"
			(click)="onCheck(true)"
			[iconCls]="'icon--light'"
		></app-icon-check>
	</div>

	<ng-template #valueIcon>
		<app-icon-check
			*ngIf="params.column.colDef.isChecked"
			(click)="onCheck(false)"
			[iconCls]="'icon--success'"
		></app-icon-check>
		<app-icon-check
			*ngIf="!params.column.colDef.isChecked"
			(click)="onCheck(true)"
			[iconCls]="'icon--light'"
		></app-icon-check>
	</ng-template>
	<p>Include Annual Visit</p>
</div>
