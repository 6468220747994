import { Pipe, PipeTransform } from '@angular/core';

// Usage: Returns the name of the item based on the value found in the provided list

// Example: we have an object which has the 'statusId' but no 'statusName' and we need to display the 'statusName' in the HTML not the 'statusId'.
// In our case we should have an endpoint retrieving the needed 'statusList' (the 'list' parameter in our pipe) which has 'statusId' and 'statusName'.
// After that, we assign the 'statusList' to the first pipe parameter and let the pipe do the work for you.
// Optional (in case the properties declarations differ form the default values: 'id' and 'name'):
// We can assign the 'itemSelector' and 'itemNameSelector' to the second and third pipe parameters to the needed values ('statusId' and 'statusName' in our example).
// Pipe example in HTML according to our example '{{ code.statusId | renderValueFromListPipe: codeChangeUtilityDataStatuses:'statusId':'statusName' }}'.

@Pipe({ name: 'renderValueFromListPipe' })
export class RenderValueFromListPipe implements PipeTransform {
	transform(
		value: number,
		list: any[],
		itemSelector = 'id',
		itemNameSelector = 'name',
		customName: string
	): string | number {
		if (list?.length > 0) {
			const item = list?.find(item => value === item[itemSelector]);
			return item ? item[itemNameSelector] : customName ? customName : value;
		}
	}
}
