import { Component, OnInit } from '@angular/core';
import { BaseIcon } from '../base-icon';

@Component({
	selector: 'app-icon-invoices',
	templateUrl: './icon-invoices.component.html',
	styleUrls: ['./icon-invoices.component.scss'],
})
export class IconInvoicesComponent extends BaseIcon implements OnInit {
	constructor() {
		super();
	}

	ngOnInit() {}
}
