import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { accountingRenewalInfoTabs } from '../constants/entity-tabs/accounting-renewal-info-tabs.constant';
import { agencyInfoTabs } from '../constants/entity-tabs/agency-info-tabs.constant';
import { analyzeProductsTabs } from '../constants/entity-tabs/analyze-products-info-tabs.constant';
import { companyApplicationTabs } from '../constants/entity-tabs/company-application-tabs.constant';
import { companyInfoTabs } from '../constants/entity-tabs/company-info-tabs.constant';
import { companyModuleTabs } from '../constants/entity-tabs/company-module-tabs.constant';
import { contactInfoTabs } from '../constants/entity-tabs/contact-info-tabs.constant';
import { currentUserSettingInfoTabs } from '../constants/entity-tabs/current-user-info-tabs.constant';
import { customerIngredientCodeChangesUtilityModuleTabs } from '../constants/entity-tabs/customer-ingredient-code-changes-utility.tabs';
import { customerIngredientInfoTabs } from '../constants/entity-tabs/customer-ingredient-info-tabs.constant';
import { customerIngredientModuleTabs } from '../constants/entity-tabs/customer-ingredient-module-tabs.constant';
import { facilityApplicationTabs } from '../constants/entity-tabs/facility-application-tabs.constant';
import { facilityInfoTabs } from '../constants/entity-tabs/facility-info-tabs.constant';
import { groupInfoTabs } from '../constants/entity-tabs/group-info-tabs.constant';
import { ingredientBatchInfoTabs } from '../constants/entity-tabs/ingredient-batch-info-constant';
import { kosherCertificateModuleTabs } from '../constants/entity-tabs/kosher-certificate-module-tabs.constant';
import { masterIngredientInfoTabs } from '../constants/entity-tabs/master-ingredient-info-tabs.constant';
import { masterIngredientModuleTabs } from '../constants/entity-tabs/master-ingredient-module-tabs.constant';
import { applicationPrivateLabelerTabs as privateLabelerApplicationTabs } from '../constants/entity-tabs/private-labeler-application-tabs.constant';
import { privateLabelerInfoTabs } from '../constants/entity-tabs/private-labeler-info-tabs.constant';
import { productBatchInfoTabs } from '../constants/entity-tabs/product-batch-info-tabs.constant';
import { productInfoTabs } from '../constants/entity-tabs/product-info-tabs.constant';
import { productModuleTabs } from '../constants/entity-tabs/product-module-tabs.constant';
import { restaurantApplicationTabs } from '../constants/entity-tabs/restaurant-application-tabs.constant';
import { settingsModuleTabs } from '../constants/entity-tabs/settings-module-tabs.constant';
import { submitCertificateTabs } from '../constants/entity-tabs/submit-certificate.constant';
import { taskModuleTabs } from '../constants/entity-tabs/task-info-tabs.constant';
import { tripInfoTabs } from '../constants/entity-tabs/trips-info-tabs.constant';
import { ukdInfoTabs } from '../constants/entity-tabs/ukd-info-tabs.constant';
import { userSettingInfoTabs } from '../constants/entity-tabs/user-settings-info-tabs.constant';
import { vendorInfoTabs } from '../constants/entity-tabs/vendor-info-tabs.constant';
import { visitInfoTabs } from '../constants/entity-tabs/visit-info-tabs.constant';
import { visitModuleTabs } from '../constants/entity-tabs/visit-module-tabs.constant';
import { ApplicationType } from '../enums/application-type.enum';
import { TabsType } from '../enums/tabs-type.enum';
import { Tab } from '../models/tab.model';
import { UrlHelperService } from './helpers/url-helper.service';
import { roleSettingInfoTabs } from '../constants/entity-tabs/role-settings-info-tabs.constant';

@Injectable({
	providedIn: 'root',
})
export class TabsService {
	tabNotifications$ = new Subject<{ value: number; tabName: string }>();

	constructor(private urlHelperService: UrlHelperService) {}

	getTabsObservable(type: TabsType, entityId?: number | string): Observable<Tab[]> {
		return new Observable(observer => {
			observer.next(this.getTabs(type, entityId));
			observer.complete();
		});
	}

	getTabs(type: TabsType, entityId?: number | string): Tab[] {
		let tabs: Tab[] = [];
		switch (type) {
			case TabsType.CompanyModule:
				tabs = cloneDeep(companyModuleTabs);
				break;
			case TabsType.CompanyInfo:
				tabs = cloneDeep(companyInfoTabs);
				break;
			case TabsType.FacilityInfo:
				tabs = cloneDeep(facilityInfoTabs);
				break;
			case TabsType.PrivateLabelerInfo:
				tabs = cloneDeep(privateLabelerInfoTabs);
				break;
			case TabsType.VendorInfo:
				tabs = cloneDeep(vendorInfoTabs);
				break;
			case TabsType.AgencyInfo:
				tabs = cloneDeep(agencyInfoTabs);
				break;
			case TabsType.ContactInfo:
				tabs = cloneDeep(contactInfoTabs);
				break;
			case TabsType.UserSettingsInfo:
				tabs = cloneDeep(userSettingInfoTabs);
				break;
			case TabsType.RoleSettingsInfo:
				tabs = cloneDeep(roleSettingInfoTabs);
				break;
			case TabsType.CurrentUserInfo:
				tabs = cloneDeep(currentUserSettingInfoTabs);
				break;
			case TabsType.VisitsInfo:
				tabs = cloneDeep(visitInfoTabs);
				break;
			case TabsType.TripsInfo:
				tabs = cloneDeep(tripInfoTabs);
				break;
			case TabsType.VisitsModule:
				tabs = cloneDeep(visitModuleTabs);
				break;
			case TabsType.SettingsModule:
				tabs = cloneDeep(settingsModuleTabs);
				break;
			case TabsType.GroupInfo:
				tabs = cloneDeep(groupInfoTabs);
				break;
			case TabsType.MasterIngredientInfo:
				tabs = cloneDeep(masterIngredientInfoTabs);
				break;
			case TabsType.CustomerIngredientInfo:
				tabs = cloneDeep(customerIngredientInfoTabs);
				break;
			case TabsType.CustomerIngredientModule:
				tabs = cloneDeep(customerIngredientModuleTabs);
				break;
			case TabsType.ProductInfo:
				tabs = cloneDeep(productInfoTabs);
				break;
			case TabsType.ProductsModule:
				tabs = cloneDeep(productModuleTabs);
				break;
			case TabsType.IngredientBatchInfo:
				tabs = cloneDeep(ingredientBatchInfoTabs);
				break;
			case TabsType.ProductBatch:
				tabs = cloneDeep(productBatchInfoTabs);
				break;
			case TabsType.AccountingRenewal:
				tabs = cloneDeep(accountingRenewalInfoTabs);
				this.setAccountingRenewalTabPath(tabs, entityId);
				break;
			case TabsType.MasterIngredientModule:
				tabs = cloneDeep(masterIngredientModuleTabs);
				break;
			case TabsType.KosherCertificateModule:
				tabs = cloneDeep(kosherCertificateModuleTabs);
				break;
			case TabsType.CompanyApplicationInfo:
				tabs = cloneDeep(companyApplicationTabs);
				this.appendApplicationTypeRoute(tabs, ApplicationType.Company);
				break;
			case TabsType.FacilityApplicationInfo:
				tabs = cloneDeep(facilityApplicationTabs);
				this.appendApplicationTypeRoute(tabs, ApplicationType.Facility);
				break;
			case TabsType.PrivateLabelerApplicationInfo:
				tabs = cloneDeep(privateLabelerApplicationTabs);
				this.appendApplicationTypeRoute(tabs, ApplicationType.PrivateLabel);
				break;
			case TabsType.RestaurantApplicationInfo:
				tabs = cloneDeep(restaurantApplicationTabs);
				this.appendApplicationTypeRoute(tabs, ApplicationType.Restaurant);
				break;
			case TabsType.UkdSearchInfo:
				tabs = cloneDeep(ukdInfoTabs);
				break;
			case TabsType.AnalyzeProductInfo:
				tabs = cloneDeep(analyzeProductsTabs);
				break;
			case TabsType.TaskInfo:
				tabs = cloneDeep(taskModuleTabs);
				break;
			case TabsType.SubmitCertificateInfo:
				tabs = cloneDeep(submitCertificateTabs);
				break;
			case TabsType.CustomerIngredientCodeChangesUtilityModuleTabs:
				tabs = cloneDeep(customerIngredientCodeChangesUtilityModuleTabs);
				break;
			case TabsType.None:
			default:
				tabs = [];
		}

		if (entityId) {
			tabs.forEach(tab => {
				tab.path = this.urlHelperService.replaceIdInUrl(tab.path, entityId);
			});
		}

		return tabs;
	}

	private appendApplicationTypeRoute(tabs: Tab[], applicationType: ApplicationType) {
		let prefix = '';
		switch (applicationType) {
			case ApplicationType.Company:
				prefix = '/applications/company-application/';
				break;
			case ApplicationType.Facility:
				prefix = '/applications/facility-application/';
				break;
			case ApplicationType.PrivateLabel:
				prefix = '/applications/private-label-application/';
				break;
			case ApplicationType.Restaurant:
				prefix = '/applications/restaurant-application/';
				break;
		}
		tabs.forEach(tab => {
			tab.path = prefix + tab.path;
		});
	}

	private setAccountingRenewalTabPath(tabs: Tab[], entityId: number | string): void {
		if (entityId) {
			tabs.forEach(t => {
				t.path = t.alternativePath ?? t.path;
			});
		}
	}
}
