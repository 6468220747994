import { CustomerIngredientsModuleTabs } from '../../enums/permission-module.enum';

export const customerIngredientInfoTabs = [
	{
		name: 'Info',
		path: `/ingredients/customer-ingredient/:id`,
		permissionId: CustomerIngredientsModuleTabs.Info,
	},
	{
		name: 'Facilities',
		path: `/ingredients/customer-ingredient/:id/facilities`,
		permissionId: CustomerIngredientsModuleTabs.Facilities,
	},
	{
		name: 'Products',
		path: `/ingredients/customer-ingredient/:id/products`,
		permissionId: CustomerIngredientsModuleTabs.Products,
	},
	{
		name: 'Subset',
		path: `/ingredients/customer-ingredient/:id/subset`,
		permissionId: CustomerIngredientsModuleTabs.Subset,
	},
	{
		name: 'Purchase History',
		path: `/ingredients/customer-ingredient/:id/purchase-history`,
		permissionId: CustomerIngredientsModuleTabs.PurchaseHistory,
	},
	{
		name: 'Guidelines',
		path: `/ingredients/customer-ingredient/:id/guidelines`,
		permissionId: CustomerIngredientsModuleTabs.Guidelines,
	},
	{
		name: 'Similar Ingredients',
		path: `/ingredients/customer-ingredient/:id/similar-ingredients`,
		permissionId: CustomerIngredientsModuleTabs.SimilarIngredients,
	},
	{
		name: 'Batch Ingredients',
		path: `/ingredients/customer-ingredient/:id/customer-related-batch-ingredients`,
		permissionId: CustomerIngredientsModuleTabs.BatchIngredients,
	},
	{
		name: 'History',
		path: `/ingredients/customer-ingredient/:id/history`,
		permissionId: CustomerIngredientsModuleTabs.History,
	},
	{
		name: 'Notes',
		path: `/ingredients/customer-ingredient/:id/notes`,
		permissionId: CustomerIngredientsModuleTabs.Notes,
	},
	{
		name: 'Tasks',
		path: `/ingredients/customer-ingredient/:id/tasks`,
		permissionId: CustomerIngredientsModuleTabs.Tasks,
	},
	{
		name: 'Dependent Products',
		path: `/ingredients/customer-ingredient/:id/dependent-products`,
		permissionId: CustomerIngredientsModuleTabs.DependentProducts,
	},
	{
		name: 'Audit Trail',
		path: `/ingredients/customer-ingredient/:id/audit-trail`,
	},
];
