import { ActiveType } from '../../core/enums/active-type.enum';
import { ActiveModeActions, ActiveModeActionTypes } from '../actions/active-mode.action';

export interface ActiveModeState {
	activeMode: ActiveType;
}

export const initialState: ActiveModeState = {
	activeMode: ActiveType.All,
};

export function activeModeReducer(state = initialState, action: ActiveModeActions): ActiveModeState {
	switch (action.type) {
		case ActiveModeActionTypes.ActiveModeUpdate:
			return {
				activeMode: action.payload.activeMode,
			};
		default:
			return state;
	}
}
