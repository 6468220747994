import { Component } from '@angular/core';
import { CheckCellRendererComponent } from './check-cell-renderer.component';

@Component({
	selector: 'app-group-check-cell-renderer',
	templateUrl: './group-check-cell-renderer.component.html',
	styleUrls: ['./check-cell-renderer.component.scss'],
})
export class GroupCheckCellRendererComponent extends CheckCellRendererComponent {
	public get isChild(): boolean {
		return !this.params.node.group && this.params.node.level > 0;
	}
}
