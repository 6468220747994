import { ApplicationModuleTabs } from '../../enums/permission-module.enum';
import { Tab } from '../../models/tab.model';

export const applicationPrivateLabelerTabs: Tab[] = [
	{
		name: 'Private Label',
		path: `:id/private-label`,
		permissionId: ApplicationModuleTabs.PrivateLabel,
	},
	{
		name: 'Company',
		path: `:id/company`,
		permissionId: ApplicationModuleTabs.Company,
	},
	{
		name: 'PL contract',
		path: `:id/pl-contract`,
		permissionId: ApplicationModuleTabs.PLcontract,
	},
	{
		name: 'Contacts',
		path: `:id/contacts`,
		permissionId: ApplicationModuleTabs.Contacts,
	},
	{
		name: 'Tasks',
		path: `:id/tasks`,
		permissionId: ApplicationModuleTabs.Tasks,
	},
	{
		name: 'Application Info',
		path: `:id/application-info`,
		permissionId: ApplicationModuleTabs.ApplicationInfo,
	},
	{
		name: 'Products',
		path: `:id/products`,
		permissionId: ApplicationModuleTabs.Products,
	},
	{
		name: 'Kosher Certificates',
		path: `:id/certificates`,
		permissionId: ApplicationModuleTabs.KosherCertificates,
	},
	{
		name: 'Invoicing Group',
		path: `:id/invoicing-group`,
		permissionId: ApplicationModuleTabs.InvoicingGroups,
	},
	{
		name: 'Audit Trail',
		path: `:id/audit-trail`,
	},
];
