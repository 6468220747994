import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromActiveMode from '../reducers/active-mode.reducer';
import { Observable } from 'rxjs';

export const selectPageTitleState = createFeatureSelector<fromActiveMode.ActiveModeState>('activeMode');

export const selectActiveMode = createSelector(selectPageTitleState, state => state.activeMode);

export const getValue = (obj: Observable<any>): any => {
	let value: any;
	obj.subscribe(v => (value = v));
	return value;
};
