import { PermissionModule } from '../../enums/permission-module.enum';

export const kosherCertificateModuleTabs = [
	{
		name: 'Kosher Certificates',
		path: `/certificates`,
		isModule: true,
		permissionId: PermissionModule.KosherCertificate,
	},
	{
		name: 'KC Groups',
		path: `/certificates/kc-groups`,
		isModule: true,
		permissionId: PermissionModule.KCGroup,
	},
];
